import { useStripe } from "@stripe/react-stripe-js";
import { CKEditor } from "ckeditor4-react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { updateArticleDraft } from "../../../redux/slices/articles";
import { getAllPaymentCards } from "../../../redux/slices/subscription";
import * as images from "../../../utilities/images";
import CardPaymentModal from "../../Modal/AddCardPaymentModal";
import CustomModal from "../../Modal/CustomModal";
import ScheduleModal from "../../Modal/ScheduleModal";
import UnderreviewModal from "../../Modal/UnderreviewModal";
import PaymentDetail from "../paymentdetail";
import { useArticleSelector } from "../../../redux/selector/article";
import Slider from "react-slick";

const AddOnDetail = ({ closess }) => {
  const stripe = useStripe();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const articleSelector = useArticleSelector();
  const { loading } = articleSelector;
  const { selectedPublication } = location?.state ||"";
  const [key, setKey] = useState(Math.random());
  const { addOnInfo } = location?.state ||"";

  const [allCardsInfo, setAllCardsInfo] = useState("");
  const [articlescheduleInfo, setArticlescheduleInfo] = useState("");
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  //closeModal
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  // open modal
  const handleOpenModal = (flag) => {
    const modalFlag = flag?.flags || flag;
    setModalDetail({
      show: true,
      flag: modalFlag,
      type: modalFlag,
    });
    setKey(Math.random());
  };

  // API for get card list (it check for first time ,if no any card save then open direct add card modal otherwise all card list modal).....................
  const getAllCardInfo = () => {
    dispatch(
      getAllPaymentCards({
        cb(res) {
          if (res.status === 200) {
            setAllCardsInfo(res?.data?.data?.data);
          }
        },
      })
    );
  };

  // this is use for pay article payment it come from callback function(CardPaymentModal)
  const paymentToken = (cardInfo) => {
    const { flag, date, time, publicationData } = articlescheduleInfo;
    if (addOnInfo?.articleType == "ai") {
      SubmitAIArticle(flag, date, time, publicationData, cardInfo?.cardId);
    } else {
      handleAddOnArticle(flag, date, time, publicationData, cardInfo?.cardId);
    }
  };

  // FUNCTION FOR USE HANDLE CREATE ARTICLE WITH PAYMENT AND NON PAYMENT//////////////////////////////////////////////
  const createAddOnArticle = (flag, time, date) => {
    const publicationData = addOnInfo?.publications?.map(
      (data) => data?.publicationId
    );
    console.log(publicationData, "publicationData11");

    setArticlescheduleInfo({ flag, date, time, publicationData });
    if (
      (addOnInfo?.reject?.[0]
        ? addOnInfo?.addonTotalAmount || 0
        : (addOnInfo?.totalAmount || 0) +
          (addOnInfo?.newAddOns?.totalAmount || 0)) > 0 &&
      flag != "1"
    ) {
      if (allCardsInfo?.length == 0) {
        handleOpenModal("paymentModal");
      } else {
        handleOpenModal("cardPaymentModal");
      }
    } else {
      if (addOnInfo?.articleType == "ai") {
        SubmitAIArticle(flag, time, date, publicationData);
      } else {
        handleAddOnArticle(flag, time, date, publicationData);
      }
    }
  };

  // API for create AI article ................
  const SubmitAIArticle = (flag, date, time, publicationData, cardId) => {
    let aiParams = {
      userId: addOnInfo?.userId,
      id: addOnInfo?._id,
      cardId: cardId || undefined,
      articleType: "ai",
      creditImage: addOnInfo?.creditImage || undefined,
      url: "addOnInfo?.url",
      title: addOnInfo?.title,
      articleBy: addOnInfo?.articleBy,
      publicationId: publicationData,
      categoryName: addOnInfo?.categoryName,
      categoryId: addOnInfo?.categoryId,
      photos: addOnInfo?.photos,
      description: addOnInfo?.description,
      saveAsDraft: flag,
      addOn: "1",
      professional: addOnInfo?.newAddOns?.professional,
      interviewer: addOnInfo?.newAddOns?.interviewer,
      coverImageFlag: addOnInfo?.newAddOns?.coverImageFlag,
      date: date || undefined,
      purpose: addOnInfo?.articlePurpose?.purpose,
      targetAudience: addOnInfo?.articlePurpose?.targetAudience,
      headline: addOnInfo?.headline || undefined, // optional
      mainMessage: addOnInfo?.keyMessages?.mainMessage,
      highlights: addOnInfo?.keyMessages?.highlights,
      desiredOutcomes: addOnInfo?.callToAction?.desiredOutcomes,
      contactInfo: addOnInfo?.callToAction?.contactInfo,
      tone: addOnInfo?.Preferences?.tone,
      style: addOnInfo?.Preferences?.style,
      additionalNote: addOnInfo?.additionalNote,
      logo: addOnInfo?.attachments?.logo || undefined,
      files: addOnInfo?.attachments?.files || undefined,
      needInterviewerAssistant: addOnInfo?.publications?.map(
        (data) => data?.needInterviewerAssistant
      ),
      needProfessionalAssistant: addOnInfo?.publications?.map(
        (data) => data?.needProfessionalAssistant
      ),
    };
    if (addOnInfo?.articleBy === "company") {
      aiParams = {
        ...aiParams,
        industry: addOnInfo?.companiesData?.basicInfo?.industry,
        companyName: addOnInfo?.companiesData?.basicInfo?.companyName,
        companyWebsite: addOnInfo?.companiesData?.basicInfo?.companyWebsite,
        briefOverview: addOnInfo?.companiesData?.companyOverview?.briefOverview,
        foundHistory:
          addOnInfo?.companiesData?.companyOverview.foundHistory || undefined, // optional
        achievements: addOnInfo?.companiesData?.companyOverview?.achievements,
        featuredProduct: addOnInfo?.companiesData?.products?.featuredProduct,
        sellingPoints: addOnInfo?.companiesData?.products?.sellingPoints,
        founderQuote:
          addOnInfo?.companiesData?.quotes?.founderQuote || undefined, // optional
        optionalQuote: addOnInfo?.companiesData?.quotes?.optionalQuote,
        companyMediaFeatures:
          addOnInfo?.companiesData?.media?.companyMediaFeatures,
        partnerhships: addOnInfo?.companiesData?.media?.partnerhships,
        companySocialMedialinks:
          addOnInfo?.companiesData?.media?.companySocialMedialinks?.[0],
        preferredMedia:
          addOnInfo?.companiesData?.focus?.preferredMedia || undefined, // optional
        geographic: addOnInfo?.companiesData?.focus?.geographic,
      };
    } else {
      aiParams = {
        ...aiParams,
        fullName: addOnInfo?.individualsData?.basicInfo?.fullName,
        professionalTitle:
          addOnInfo?.individualsData?.basicInfo?.professionalTitle,
        industry: addOnInfo?.individualsData?.basicInfo?.industry,
        individualWebsite:
          addOnInfo?.individualsData?.basicInfo?.individualWebsite,
        bio: addOnInfo?.individualsData?.personalInfo?.bio,
        accomplishment:
          addOnInfo?.individualsData?.personalInfo?.accomplishment,
        philosophy: addOnInfo?.individualsData?.personalInfo?.philosophy,
        currentPosition:
          addOnInfo?.individualsData?.professionalExperiennce?.currentPosition,
        previousRole:
          addOnInfo?.individualsData?.professionalExperiennce?.previousRole ||
          undefined, // optional
        projects: addOnInfo?.individualsData?.professionalExperiennce?.projects,
        quote: addOnInfo?.individualsData?.quote || undefined, // optional
        individualMediaFeature:
          addOnInfo?.individualsData?.media?.individualMediaFeature,
        individualSocialMedialinks: [
          addOnInfo?.individualsData?.media?.individualSocialMedialinks,
        ],
      };
    }

    if (addOnInfo?.addOns?.coverImageUrl) {
      aiParams = {
        ...aiParams,
        coverImageUrl: addOnInfo?.addOns?.coverImageUrl,
      };
    }

    if (time) {
      aiParams = {
        ...aiParams,
        time: moment(time).format("HH:mm"),
      };
    }
    dispatch(
      updateArticleDraft({
        ...aiParams,
        async cb(res) {
          if (res?.data?.data?.status === "under_review") {
            handleOpenModal("underreviewModal");
          } else if (res?.data?.data?.status === "schedule") {
            navigate("/drafts", { state: { flag: "schedule" } });
          } else if (res?.data?.data?.status === "draft") {
            navigate("/drafts");
          }
          // if (
          //   res?.data?.data?.paymentIntent?.client_secret &&
          //   res?.data?.data?.paymentIntent?.status == "requires_confirmation"
          // ) {
          //   try {
          //     const paymentResult = await stripe.confirmCardPayment(
          //       res?.data?.data?.paymentIntent?.client_secret
          //     );
          //     if (paymentResult.error) {
          //       toast.error("Payment confirmation error");
          //       return;
          //     } else {
          //       toast.success("Article payment successfully");
          //     }
          //   } catch (error) {
          //     console.error("Payment error:", error);
          //   }
          // }
        },
      })
    );
  };

  const handleAddOnArticle = (flag, date, time, publicationData, cardId) => {
    let params = {
      userId: addOnInfo?.userId,
      id: addOnInfo._id,
      cardId: cardId || undefined,
      articleType: "upload",
      creditImage: addOnInfo?.creditImage || undefined,
      url: addOnInfo?.url,
      publicationId: publicationData,
      title: addOnInfo?.title,
      description: addOnInfo?.description || undefined,
      googleDriveUrl: addOnInfo?.googleDriveUrl || undefined,
      photos: addOnInfo?.photos,
      saveAsDraft: flag,
      addOn: "1",
      professional: addOnInfo?.newAddOns?.professional,
      interviewer: addOnInfo?.newAddOns?.interviewer,
      coverImageFlag: addOnInfo?.newAddOns?.coverImageFlag,
      multipleImages: addOnInfo?.multipleImages || undefined,
      date: date || undefined,
      needInterviewerAssistant: addOnInfo?.publications?.map(
        (data) => data?.needInterviewerAssistant
      ),
      needProfessionalAssistant: addOnInfo?.publications?.map(
        (data) => data?.needProfessionalAssistant
      ),
    };
    if (addOnInfo?.addOns?.coverImageUrl) {
      params = {
        ...params,
        coverImageUrl: addOnInfo?.addOns?.coverImageUrl,
      };
    }
    if (time) {
      params = {
        ...params,
        time: moment(time).format("HH:mm"),
      };
    }

    dispatch(
      updateArticleDraft({
        ...params,
        async cb(res) {
          if (res?.data?.data?.status === "under_review") {
            handleOpenModal("underreviewModal");
          } else if (res?.data?.data?.status === "schedule") {
            navigate("/drafts", { state: { flag: "schedule" } });
          } else if (res?.data?.data?.status === "draft") {
            navigate("/drafts");
          }
          // if (
          //   res?.data?.data?.paymentIntent?.client_secret &&
          //   res?.data?.data?.paymentIntent?.status == "requires_confirmation"
          // ) {
          //   try {
          //     const paymentResult = await stripe.confirmCardPayment(
          //       res?.data?.data?.paymentIntent?.client_secret
          //     );
          //     if (paymentResult.error) {
          //       toast.error("Payment confirmation error");
          //       return;
          //     } else {
          //       toast.success("Article payment successfully");
          //     }
          //   } catch (error) {
          //     console.error("Payment error:", error);
          //   }
          // }
        },
      })
    );
  };

  useEffect(() => {
    getAllCardInfo();
  }, []);

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={onClick}
      >
        <i
          className="fas fa-arrow-right"
          style={{
            fontSize: "15px",
            color: "#FFF",
            padding: "10px",
            backgroundColor: "#ff5c5d",
            borderRadius: "50%",
            marginRight: "10px 11px",
          }}
        ></i>
      </div>
    );
  }

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={onClick}
      >
        <i
          className="fas fa-arrow-left"
          style={{
            fontSize: "15px",
            color: "#FFF",
            padding: "10px",
            backgroundColor: "#ff5c5d",
            borderRadius: "50%",
            marginLeft: "10px 11px",
          }}
        ></i>
      </div>
    );
  }

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SamplePrevArrow />,
    prevArrow: <SampleNextArrow />,

    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          initialSlide: 2,
        },
      },
    ],
  };


  return (
    <>
      <div className="content-wrapper  addOnArticleDetailSec pt-0">
        <div className="articleDetail_Fixed">
        <div className="innerHead my-3 justify-content-between">
          <div className="d-flex align-items-center">
            <img
              src={images.arrowLink}
              className="img-fluid pointCursor"
              alt="arrowLink image"
              onClick={() => {
                navigate("/addOn-Article", {
                  state: {
                    ...location?.state,
                    flag: "AiArticle",
                  },
                });
              }}
            />
            <h2 className="innerHeadText ms-2">Add-On Details</h2>
          </div>
          <h4 className="categoryText fw-400">
            {moment().format("MM/DD/YYYY")}
          </h4>
        </div>
        <h5 className="heading_22">
          Article Title:{" "}
          <span className="form_subheading"> {addOnInfo?.title}</span>
        </h5>

        {addOnInfo?.articleType == "ai" && (
          <div className="articleSubcategory_ mt-3 articles_SubCategory">
            <h4 className="categoryText fw-400 ">
              Article Genre:
              <strong className="textWhite fw-500 ms-2">
                {addOnInfo?.categoryName}
              </strong>
            </h4>
            {/* <h4 className="categoryText fw-400 ">
            Type:
            <strong className="textWhite fw-500 ms-2">
              {selectedPublication?.subCategoryData?.name}
            </strong>
          </h4> */}
          </div>
        )}

        <div className="articlePublication_ article_Publication_Resp mt-3">
          <h5 className="heading_22">Publication:</h5>
          {addOnInfo?.publications?.length > 0 ? (
            addOnInfo?.publications?.map((val, index) => (
              <div
                className="publicationMedia_ publication_Media_Resp"
                key={index}
              >
                <div className="mediaInner_ d-flex align-items-center">
                  <img
                    src={
                      val?.publicationLogo
                        ? val?.publicationLogo
                        : images.media1
                    }
                    className="img-fluid me-2"
                    alt="media_image"
                  />
                  <span className="form_subheading">
                    {val?.publicationName}
                  </span>
                </div>
              </div>
            ))
          ) : (
            <td colSpan={8} className="noFoundHead">
              <p className="text-center noFoundText">No publication Found!</p>
            </td>
          )}
        </div>
        {addOnInfo?.url && (
          <div className="articlePublication_ article_Publication_Resp mt-3">
            <h5 className="heading_22">Url:</h5>
            <div className="mediaInner_ d-flex align-items-center">
              {/* <Link target="_blank" > */}
              <a
                href={addOnInfo?.url}
                target="_blank"
                className="form_subheading"
              >
                {addOnInfo?.url}
              </a>
              {/* </Link> */}
            </div>
          </div>
        )}
        </div>

        <div className="articleImage mt-4">
          {addOnInfo?.photos?.length > 0 && (
            <img
              src={addOnInfo?.photos[0]}
              className="img-fluid me-2 previewImage"
              alt="media_image"
            />
          )}
        </div>
        {addOnInfo?.creditImage && (
          <>
            <p className="form_subheading text-center mt-2">
              {addOnInfo?.creditImage}
            </p>
          </>
        )}

        <div className="contentPreview_ mt-3">
          <div className="row">
            <div
              dangerouslySetInnerHTML={{
                __html: addOnInfo?.description,
              }}
            />
          </div>
          <div style={{ display: "none" }}>
            <CKEditor
              initData={""}
              config={{
                extraPlugins: "easyimage",
                versionCheck: false,
              }}
            />
          </div>
        </div>
        <div className="row articleSlider_Row">
          {/* {addOnInfo?.multipleImages?.length > 0 &&
            addOnInfo?.multipleImages?.map((item) => (
              <div className="col-md-4">
                <div className="mt-3">
                  <img
                    src={item}
                    className="img-fluid articleCover_Img"
                    alt="media_image"
                  />
                </div>
              </div>
            ))} */}
 <div className="slider-container mt-4 mb-4 articleMultiImg_Slider">
          {addOnInfo?.multipleImages?.length > 1 ? (
            <Slider {...settings}>
              {addOnInfo?.multipleImages?.map((item, index) => (
                <div className="p-2">
                  <a
                    key={index}
                    // href={logo?.link}
                    className="marquee_Links"
                    target="_blank"
                  >
                    <div className="client_Logoes">
                      <img
                        src={item}
                        alt="slider_img"
                        className="img-fluid w-100 h-100"
                      />
                    </div>
                  </a>
                </div>
              ))}
            </Slider>
          ) : (
            <>
              {addOnInfo?.multipleImages?.map((item) => (
                <div className="col-md-4">
                  <div className="mt-3">
                    <img
                      src={item}
                      className="img-fluid articleCover_Img"
                      alt="media_image"
                    />
                  </div>
                </div>
              ))}
            </>
          )}
          </div>
        </div>

        {addOnInfo?.googleDriveUrl && (
          <div className="articlePublication_ article_Publication_Resp mt-3">
            <h5 className="heading_22">Google Drive Url :-</h5>
            <div className="mediaInner_ d-flex align-items-center">
              <a
                className="form_subheading"
                href={addOnInfo?.googleDriveUrl}
                target="_blank"
              >
                {addOnInfo?.googleDriveUrl}
              </a>
            </div>
          </div>
        )}

        {addOnInfo?.articleType === "ai" && (
          <>
            {addOnInfo?.articleBy !== "company" && (
              <>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="aiForm_Detail_Parent">
                      <h3 className="aiForm_Detail_Head">Basic Information</h3>
                      <ul>
                        <li className="formDetail_List">
                          Full Name:{" "}
                          <span className="ps-2">
                            {addOnInfo?.individualsData?.basicInfo?.fullName}
                          </span>
                        </li>
                        <li className="formDetail_List">
                          Professional Title:{" "}
                          <span className="ps-2">
                            {
                              addOnInfo?.individualsData?.basicInfo
                                ?.professionalTitle
                            }
                          </span>
                        </li>
                        <li className="formDetail_List">
                          Industry/Field:{" "}
                          <span className="ps-2">
                            {addOnInfo?.individualsData?.basicInfo?.industry}
                          </span>
                        </li>
                        <li className="formDetail_List">
                          Website:{" "}
                          <span className="ps-2">
                            {
                              addOnInfo?.individualsData?.basicInfo
                                ?.individualWebsite
                            }
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-6 ">
                    <div className="aiForm_Detail_Parent">
                      <h3 className="aiForm_Detail_Head">
                        Personal Background Information
                      </h3>
                      <ul>
                        <li className="formDetail_List">
                          {" "}
                          Brief Overview:
                          <span className="ps-2">
                            {addOnInfo?.individualsData?.personalInfo?.bio}
                          </span>
                        </li>
                        <li className="formDetail_List">
                          {" "}
                          Key Accomplishments:
                          <span className="ps-2">
                            {
                              addOnInfo?.individualsData?.personalInfo
                                ?.accomplishment
                            }
                          </span>
                        </li>
                        <li className="formDetail_List">
                          Personal Values or Philosophy:{" "}
                          <span className="ps-2">
                            {
                              addOnInfo?.individualsData?.personalInfo
                                ?.philosophy
                            }
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-6 ">
                    <div className="aiForm_Detail_Parent">
                      <h3 className="aiForm_Detail_Head">
                        {" "}
                        Professional Experience
                      </h3>
                      <ul>
                        {addOnInfo?.individualsData?.professionalExperiennce
                          ?.previousRole && (
                          <li className="formDetail_List">
                            {" "}
                            Previous Notable Roles:
                            <span className="ps-2">
                              {
                                addOnInfo?.individualsData
                                  ?.professionalExperiennce?.previousRole
                              }
                            </span>
                          </li>
                        )}

                        <li className="formDetail_List">
                          Current Role/Position:
                          <span className="ps-2">
                            {
                              addOnInfo?.individualsData
                                ?.professionalExperiennce?.currentPosition
                            }
                          </span>
                        </li>
                        <li className="formDetail_List">
                          {" "}
                          Major Projects/Contributions:
                          <span className="ps-2">
                            {
                              addOnInfo?.individualsData
                                ?.professionalExperiennce?.projects
                            }
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-6 ">
                    <div className="aiForm_Detail_Parent">
                      <h3 className="aiForm_Detail_Head">
                        Media or Social Proof
                      </h3>
                      <ul>
                        <li className="formDetail_List">
                          Media Features/Recognition:
                          <span className="ps-2">
                            {
                              addOnInfo?.individualsData?.media
                                ?.individualMediaFeature
                            }
                          </span>
                        </li>
                        <li className="formDetail_List">
                          Social Media Links:{" "}
                          <span className="ps-2">
                            {
                              addOnInfo?.individualsData?.media
                                ?.individualSocialMedialinks
                            }
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {addOnInfo?.individualsData?.quote && (
                    <div className="col-lg-6 ">
                      <div className="aiForm_Detail_Parent">
                        <h3 className="aiForm_Detail_Head">Quote</h3>
                        <ul>
                          <li className="formDetail_List">
                            Personal Quote:
                            <span className="ps-2">
                              {addOnInfo?.individualsData?.quote}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}

            {addOnInfo?.articleBy === "company" && (
              <>
                {/*  Company */}
                <div className="row">
                  <div className="col-lg-6">
                    <div className="aiForm_Detail_Parent">
                      <h3 className="aiForm_Detail_Head">Basic Information</h3>
                      <ul>
                        <li className="formDetail_List">
                          Brand/Company Name:{" "}
                          <span className="ps-2">
                            {addOnInfo?.companiesData?.basicInfo?.companyName}
                          </span>
                        </li>
                        <li className="formDetail_List">
                          Industry/Field:{" "}
                          <span className="ps-2">
                            {addOnInfo?.companiesData?.basicInfo?.industry}
                          </span>
                        </li>
                        <li className="formDetail_List">
                          Website:{" "}
                          <span className="ps-2">
                            {
                              addOnInfo?.companiesData?.basicInfo
                                ?.companyWebsite
                            }
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="aiForm_Detail_Parent">
                      <h3 className="aiForm_Detail_Head">Company Overview</h3>
                      <ul>
                        <li className="formDetail_List">
                          Brief Overview:{" "}
                          <span className="ps-2">
                            {
                              addOnInfo?.companiesData?.companyOverview
                                ?.briefOverview
                            }
                          </span>
                        </li>
                        <li className="formDetail_List">
                          Notable Achievements:{" "}
                          <span className="ps-2">
                            {
                              addOnInfo?.companiesData?.companyOverview
                                ?.achievements
                            }
                          </span>
                        </li>
                        {addOnInfo?.companiesData?.companyOverview
                          .foundHistory && (
                          <li className="formDetail_List">
                            Founding Story:{" "}
                            <span className="ps-2">
                              {
                                addOnInfo?.companiesData?.companyOverview
                                  .foundHistory
                              }
                            </span>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="aiForm_Detail_Parent">
                      <h3 className="aiForm_Detail_Head">Products/Services</h3>
                      <ul>
                        <li className="formDetail_List">
                          Featured Products/Services:{" "}
                          <span className="ps-2">
                            {
                              addOnInfo?.companiesData?.products
                                ?.featuredProduct
                            }
                          </span>
                        </li>
                        <li className="formDetail_List">
                          NUnique Selling Points:{" "}
                          <span className="ps-2">
                            {addOnInfo?.companiesData?.products?.sellingPoints}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="aiForm_Detail_Parent">
                      <h3 className="aiForm_Detail_Head">
                        Executive Leadership Quotes
                      </h3>
                      <ul>
                        {addOnInfo?.companiesData?.quotes?.founderQuote && (
                          <li className="formDetail_List">
                            CEO/Founder Quotes:{" "}
                            <span className="ps-2">
                              {addOnInfo?.companiesData?.quotes?.founderQuote}
                            </span>
                          </li>
                        )}

                        <li className="formDetail_List">
                          Optional Customer/Partner Quotes:{" "}
                          <span className="ps-2">
                            {addOnInfo?.companiesData?.quotes?.optionalQuote}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="aiForm_Detail_Parent">
                      <h3 className="aiForm_Detail_Head">
                        Media or Social Proof
                      </h3>
                      <ul>
                        <li className="formDetail_List">
                          Previous Media Features:{" "}
                          <span className="ps-2">
                            {
                              addOnInfo?.companiesData?.media
                                ?.companyMediaFeatures
                            }
                          </span>
                        </li>
                        <li className="formDetail_List">
                          Notable Partnerships:{" "}
                          <span className="ps-2">
                            {addOnInfo?.companiesData?.media?.partnerhships}
                          </span>
                        </li>
                        <li className="formDetail_List">
                          Social Media Presence:{" "}
                          <span className="ps-2">
                            {
                              addOnInfo?.companiesData?.media
                                ?.companySocialMedialinks
                            }
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="aiForm_Detail_Parent">
                      <h3 className="aiForm_Detail_Head">
                        Targeted Media Outlet/Industry Focus
                      </h3>
                      <ul>
                        {addOnInfo?.companiesData?.focus?.preferredMedia && (
                          <li className="formDetail_List">
                            Preferred Media Outlet:{" "}
                            <span className="ps-2">
                              {addOnInfo?.companiesData?.focus?.preferredMedia}
                            </span>
                          </li>
                        )}

                        <li className="formDetail_List">
                          Geographic Focus:
                          <span className="ps-2">
                            {addOnInfo?.companiesData?.focus?.geographic}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </>
            )}

            {/* Common */}
            <div className="row">
              <div className="col-lg-6 ">
                <div className="aiForm_Detail_Parent">
                  <h3 className="aiForm_Detail_Head">Additional Notes</h3>
                  <ul>
                    <li className="formDetail_List">
                      Special Instructions:
                      <span className="ps-2">{addOnInfo?.additionalNote}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6 ">
                <div className="aiForm_Detail_Parent">
                  <h3 className="aiForm_Detail_Head"> Key Messages</h3>
                  <ul>
                    <li className="formDetail_List">
                      Main Message/Theme:
                      <span className="ps-2">
                        {addOnInfo?.keyMessages?.mainMessage}
                      </span>
                    </li>
                    {addOnInfo?.headline && (
                      <li className="formDetail_List">
                        Top 3 Highlights:
                        <span className="ps-2">{addOnInfo?.headline}</span>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
              <div className="col-lg-6 ">
                <div className="aiForm_Detail_Parent">
                  <h3 className="aiForm_Detail_Head">Call to Action</h3>
                  <ul>
                    <li className="formDetail_List">
                      Desired Outcome:
                      <span className="ps-2">
                        {addOnInfo?.callToAction?.desiredOutcomes}
                      </span>
                    </li>
                    <li className="formDetail_List">
                      Contact Information:
                      <span className="ps-2">
                        {addOnInfo?.callToAction?.contactInfo}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6 ">
                <div className="aiForm_Detail_Parent">
                  <h3 className="aiForm_Detail_Head">
                    Tone and Style Preferences
                  </h3>
                  <ul>
                    <li className="formDetail_List">
                      Preferred Tone:
                      <span className="ps-2">
                        {addOnInfo?.Preferences?.tone}
                      </span>
                    </li>
                    <li className="formDetail_List">
                      Writing Style:
                      <span className="ps-2">
                        {addOnInfo?.Preferences?.style}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6 ">
                <div className="aiForm_Detail_Parent">
                  <h3 className="aiForm_Detail_Head">Article Purpose/Goal</h3>
                  <ul>
                    <li className="formDetail_List">
                      Purpose of the Article:
                      <span className="ps-2">
                        {addOnInfo?.articlePurpose?.purpose}
                      </span>
                    </li>
                    <li className="formDetail_List">
                      Target Audience:
                      <span className="ps-2">
                        {addOnInfo?.articlePurpose?.targetAudience}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6 ">
                <div className="aiForm_Detail_Parent">
                  <h3 className="aiForm_Detail_Head">Headline/Title</h3>
                  <ul>
                    <li className="formDetail_List">
                      Suggested Headline:
                      <span className="ps-2">
                        {addOnInfo?.keyMessages?.highlights}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-12 ">
                {addOnInfo?.attachments?.logo && (
                  <div className="aiForm_Detail_Parent">
                    <h3 className="aiForm_Detail_Head">Headline/Title</h3>

                    <ul>
                      <li className="formDetail_List mb-0">Upload Image:</li>
                    </ul>
                    <img
                      src={addOnInfo?.attachments?.logo}
                      alt="preimage1"
                      className="aiFormUploadImg"
                    />
                  </div>
                )}
                {addOnInfo?.attachments?.files && (
                  <div className="aiForm_Detail_Parent">
                    <ul>
                      <li className="formDetail_List mb-0">
                        Additional Files:
                      </li>
                      <span className="ps-2">
                        <a
                          className="form_subheading pointCursor"
                          href={addOnInfo?.attachments?.files}
                          target="_blank"
                          // className="me-3 text-center pointCursor"
                        >
                          {addOnInfo?.attachments?.files}
                        </a>
                      </span>
                    </ul>
                    <div className="docfile_Parent">
                      {/* <img
                                  src={images.DOCImage}
                                  alt="preimage1"
                                  className="docfile_Img"
                                /> */}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        )}

        <div className="addOnDetailSec">
          <div className="offerDetailSec mt-5">
            <h4 className="stateText">Offer Details</h4>
            <div className="offerDetailBox mt-2">
              <div className="offerUpper">
                <h6 className="ratingText">Item</h6>
                <h6 className="ratingText">Price</h6>
              </div>
              <div className="offerLower">
                <h6 className="ratingText">{addOnInfo?.title}</h6>
                <h6 className="ratingText">
                  {addOnInfo?.reject?.[0]
                    ? "$" + `${addOnInfo?.totalAmount || 0} Paid`
                    : "$" + addOnInfo?.totalAmount || 0}
                </h6>
              </div>
            </div>
          </div>
        </div>
        <div className="addOnDetailSec mt-5">
          <div className="offerDetailSec mt-2">
            <h4 className="stateText">Add-on Details</h4>
            {addOnInfo?.addOns?.professional ||
            addOnInfo?.addOns?.interviewer ||
            addOnInfo?.newAddOns?.interviewer ||
            addOnInfo?.newAddOns?.professional ? (
              <div className="offerDetailBox mt-2">
                <div className="offerUpper">
                  <h6 className="ratingText">Item</h6>
                  <h6 className="ratingText">Price</h6>
                </div>
                {(addOnInfo?.addOns?.professional ||
                  addOnInfo?.newAddOns?.professional) && (
                  <div className="offerLower">
                    <h6 className="ratingText">Professional</h6>
                    <h6 className="ratingText">
                      $
                      {addOnInfo?.reject?.[0]
                        ? addOnInfo?.addOns?.professionalAmout
                          ? `${addOnInfo?.addOns?.professionalAmout || 0} Paid`
                          : addOnInfo?.newAddOns?.professionalAmout
                        : addOnInfo?.newAddOns?.professionalAmout || 0}
                    </h6>
                  </div>
                )}

                <div className="offerLower addOnArticle_Checkbox mr-3 pt-0 justify-content-start">
                  {addOnInfo?.newAddOns?.professional && (
                    <>
                      {addOnInfo?.publications?.length > 0
                        ? addOnInfo.publications
                            .filter((item) => item?.needProfessionalAssistant)
                            .map((item, idx) => (
                              <div className=" d-flex align-items-center">
                                <input
                                  type="checkbox"
                                  id={idx}
                                  className="form-check-input addOnArticle_CheckBox mt-0"
                                  checked={item?.needProfessionalAssistant}
                                />
                                <h4 className="sub_p_text ms-2" key={item.id}>
                                  {item?.publicationName}
                                </h4>
                              </div>
                            ))
                        : ""}
                    </>
                  )}
                </div>

                {(addOnInfo?.addOns?.interviewer ||
                  addOnInfo?.newAddOns?.interviewer) && (
                  <div className="offerLower">
                    <h6 className="ratingText">Interviewer</h6>
                    <h6 className="ratingText">
                      {/* ${addOnInfo?.reject?.[0] ? `${addOnInfo?.addOns?.interviewerAmount ||0} Paid` :addOnInfo?.newAddOns?.interviewerAmount } */}
                      $
                      {addOnInfo?.reject?.[0]
                        ? addOnInfo?.addOns?.interviewerAmount
                          ? `${addOnInfo?.addOns?.interviewerAmount || 0} Paid`
                          : addOnInfo?.newAddOns?.interviewerAmount
                        : addOnInfo?.newAddOns?.interviewerAmount}
                    </h6>
                  </div>
                )}

                <div className="offerLower addOnArticle_Checkbox mr-3 pt-0 justify-content-start">
                  {addOnInfo?.newAddOns?.interviewer && (
                    <>
                      {addOnInfo?.publications?.length > 0
                        ? addOnInfo.publications
                            .filter((item) => item?.needInterviewerAssistant)
                            .map((item, idx) => (
                              <div className=" d-flex align-items-center">
                                <input
                                  type="checkbox"
                                  id={idx}
                                  className="form-check-input addOnArticle_CheckBox mt-0"
                                  checked={item?.needInterviewerAssistant}
                                />
                                <h4 className="sub_p_text ms-2" key={item.id}>
                                  {item?.publicationName}
                                </h4>
                              </div>
                            ))
                        : ""}
                    </>
                  )}
                </div>
              </div>
            ) : (
              ""
            )}

            <div className="totalBox">
              <h6 className="ratingText">Add On Total</h6>
              <h6 className="ratingText">
                $
                {addOnInfo?.reject?.[0]
                  ? addOnInfo?.addOns?.interviewer &&
                    addOnInfo?.addOns?.professional
                    ? `${addOnInfo?.addOns?.totalAmount || 0} Paid`
                    : addOnInfo?.addonTotalAmount || 0
                  : addOnInfo?.newAddOns?.totalAmount || 0}
              </h6>
            </div>
          </div>
        </div>
        <div className="totalBox">
          <h6 className="ratingText">Total Payment</h6>
          <h6 className="ratingText">
            $
            {addOnInfo?.reject?.[0]
              ? addOnInfo?.addOns?.interviewer &&
                addOnInfo?.addOns?.professional
                ? `${addOnInfo?.totalPaidAmount || 0} Paid`
                : addOnInfo?.addonTotalAmount || 0
              : (addOnInfo?.totalAmount || 0) +
                (addOnInfo?.newAddOns?.totalAmount || 0)}
          </h6>
        </div>
        <div className="d-flex align-items-center">
          <button
            className="signUpBtn  me-3 mt-5"
            type="button"
            onClick={() => {
              navigate("/addOn-Article", {
                state: {
                  ...location?.state,
                  flag: "AiArticle",
                },
              });
            }}
          >
            Back
          </button>
          <button
            className="signUpBtn  me-3 mt-5"
            type="button"
            onClick={() => createAddOnArticle("0")}
          >
            Submit
            {loading && (
              <span className="spinner-border spinner-border-sm ms-2"></span>
            )}
          </button>

          <button
            className="secondaryBtb_ mt-5 me-3 "
            onClick={(e) => createAddOnArticle("1")}
          >
            Draft
          </button>

          <button
            className="secondaryBtb_ mt-5  me-3 "
            onClick={() => {
              handleOpenModal("scheduleModal");
            }}
          >
            Schedule
          </button>
        </div>
      </div>
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={true}
        mediumWidth={false}
        ids={
          modalDetail.flag === "underreviewModal"
            ? "underreviewModal"
            : modalDetail.flag === "paymentModal"
            ? "paymentModal"
            : modalDetail.flag === "paymentModal"
            ? "paymentModal"
            : modalDetail.flag === "cardPaymentModal"
            ? "cardPaymentModal"
            : ""
        }
        child={
          modalDetail.flag === "scheduleModal" ? (
            <ScheduleModal
              createNewArticle={(flag, time, date) => {
                createAddOnArticle(flag, time, date);
              }}
            />
          ) : modalDetail.flag === "underreviewModal" ? (
            <UnderreviewModal close={() => handleOnCloseModal()} />
          ) : modalDetail.flag === "paymentModal" ? (
            <PaymentDetail
              close={() => handleOnCloseModal()}
              handleOpenModal={handleOpenModal}
              paymentToken={paymentToken}
              paymentFlag={"articlePayment"}
            />
          ) : modalDetail.flag === "cardPaymentModal" ? (
            <CardPaymentModal
              close={() => handleOnCloseModal()}
              paymentToken={paymentToken}
              paymentFlag={"articlePayment"}
              totalAmount={
                addOnInfo?.reject?.[0]
                  ? addOnInfo?.addonTotalAmount || 0
                  : (addOnInfo?.totalAmount || 0) +
                    (addOnInfo?.newAddOns?.totalAmount || 0)
              }
              handleOpenModal={(data) => handleOpenModal(data)}
            />
          ) : (
            ""
          )
        }
        header={
          modalDetail.flag === "scheduleModal" ? (
            <>
              <h2 className="modal_Heading">Schedule For Later</h2>
              <p onClick={handleOnCloseModal} className="modal_cancel">
                <img src={images.modalCross} className="ModalCance" alt="" />
              </p>
            </>
          ) : modalDetail.flag === "paymentModal" ? (
            <>
              <h2 className="modal_Heading">Add Card</h2>
              <p onClick={handleOnCloseModal} className="modal_cancel">
                <img src={images.modalCross} className="ModalCance" alt="" />
              </p>
            </>
          ) : modalDetail.flag === "cardPaymentModal" ? (
            <>
              <h2 className="modal_Heading">
                Payment is for Add-On Publications
              </h2>
              <p onClick={handleOnCloseModal} className="modal_cancel">
                <img src={images.modalCross} className="ModalCance" alt="" />
              </p>
            </>
          ) : (
            ""
          )
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  );
};

export default AddOnDetail;
