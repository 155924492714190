import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React, { useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch } from "react-redux";
import { CKEditor } from "ckeditor4-react";
import "../../../../public/css/ckEditor.css";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useWebSelector } from "../../../../redux/selector/web";
import {
  articleCategory,
  uploadArticleImage,
} from "../../../../redux/slices/web";
import * as images from "../../../../utilities/images";
import CropImageModal from "../../../Modal/CropImageModal";
import { useConvertMarkDownHtml } from "../../../../redux/customHooks/useConvertMarkDownHtml";
import CustomModal from "../../../Modal/CustomModal";
// Forward ref wrapper for CKEditor
const CKEditorWithRef = React.forwardRef((props, ref) => (
  <CKEditor {...props} ref={ref} />
));


const ArticleCreation = (props) => {
  const toastId = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const webSelector = useWebSelector();
  const { state } = location;
  const {draftData} = state || ""
  console.log(draftData
    , "sandy123");
    const [isLoading, setIsLoading] = useState(true);
  const [category, setCategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [photoUrl, setPhotoUploadUrl] = useState("");
  const [logo, setLogo] = useState(state?.data?.logo || draftData?.attachments?.logo ||"");

  const [files, setFiles] = useState(state?.data?.files || draftData?.attachments?.files||"");
  const [bannerUploadLoader, setBannerUploadLoader] = useState(false);
  const [logoUploadLoader, setLogoUploadLoader] = useState(false);
  const [fileUploadLoader, setFileUploadLoader] = useState(false);

  const [data, setData] = useState({
    entityName: state?.data?.entityName ,
    news: state?.data?.news,
    callAction: state?.data?.callAction,
    relevantKeyWords: state?.data?.relevantKeyWords,
    links: state?.data?.links,
    title: state?.data?.title || draftData?.title,
    photo: state?.data?.bannerImgUpload,
    articleUrl: state?.data?.url || state?.data?.articleUrl,
    purpose: state?.data?.purpose || draftData?.articlePurpose?.purpose||"",
    targetAudience: state?.data?.targetAudience || draftData?.articlePurpose?.targetAudience||"",
    headline: state?.data?.headline || draftData?.headline||"", // optional
    mainMessage: state?.data?.mainMessage ||draftData?.keyMessages?.mainMessage|| "",
    highlights: state?.data?.highlights || draftData?.keyMessages?.highlights||"",
    desiredOutcomes: state?.data?.desiredOutcomes || draftData?.callToAction?.desiredOutcomes||"",
    contactInfo: state?.data?.contactInfo || draftData?.callToAction?.contactInfo||"",
    tone: state?.data?.tone || draftData?.Preferences?.tone||"",
    style: state?.data?.style ||draftData?.Preferences?.style|| "",
    additionalNote: state?.data?.additionalNote || draftData?.additionalNote||"",
  });
  console.log(data, "datadatadata");
  useEffect(() => {
    setData((prev) => ({ ...prev, logo: logo, files: files }));
  }, [logo, files]);

  const [companyData, setCompanyData] = useState({
    companyName: state?.data?.companyName || draftData?.companiesData?.basicInfo?.companyName ||"",
    industry: state?.data?.industry ||draftData?.companiesData?.basicInfo?.industry|| "",
    companyWebsite: state?.data?.companyWebsite||draftData?.companiesData?.basicInfo?.companyWebsite || "",
    briefOverview: state?.data?.briefOverview ||draftData?.companiesData?.companyOverview?.briefOverview|| "",
    foundHistory: state?.data?.foundHistory || draftData?.companiesData?.companyOverview.foundHistory||"",
    achievements: state?.data?.achievements || draftData?.companiesData?.companyOverview?.achievements||"",
    featuredProduct: state?.data?.featuredProduct || draftData?.companiesData?.products?.featuredProduct||"",
    sellingPoints: state?.data?.sellingPoints || draftData?.companiesData?.products?.sellingPoints||"",
    founderQuote: state?.data?.founderQuote || draftData?.companiesData?.quotes?.founderQuote||"",
    optionalQuote: state?.data?.optionalQuote || draftData?.companiesData?.quotes?.optionalQuote||"",
    companyMediaFeatures: state?.data?.companyMediaFeatures ||draftData?.companiesData?.media?.companyMediaFeatures|| "",
    partnerhships: state?.data?.partnerhships ||draftData?.companiesData?.media?.partnerhships|| "",
    companySocialMedialinks: state?.data?.companySocialMedialinks || draftData?.companiesData?.media?.companySocialMedialinks?.[0]||"",
    preferredMedia: state?.data?.preferredMedia || draftData?.companiesData?.focus?.preferredMedia||"",
    geographic: state?.data?.geographic || draftData?.companiesData?.focus?.geographic||"",
  });
  console.log(companyData,"companyData");
  

  const [individualData, setIndividualData] = useState({
    fullName: state?.data?.fullName || draftData?.individualsData?.basicInfo?.fullName||"",
    industry: state?.data?.industry ||draftData?.individualsData?.basicInfo?.industry|| "",
    professionalTitle: state?.data?.professionalTitle || draftData?.individualsData?.basicInfo?.professionalTitle||"",
    individualWebsite: state?.data?.individualWebsite || draftData?.individualsData?.basicInfo?.individualWebsite||"",
    bio: state?.data?.bio || draftData?.individualsData?.personalInfo?.bio||"",
    accomplishment: state?.data?.accomplishment ||draftData?.individualsData?.personalInfo?.accomplishment||"",
    philosophy: state?.data?.philosophy || draftData?.individualsData?.personalInfo?.philosophy||"",
    currentPosition: state?.data?.currentPosition || draftData?.individualsData?.professionalExperiennce?.currentPosition||"",
    previousRole: state?.data?.previousRole || draftData?.individualsData?.professionalExperiennce?.previousRole||"",
    projects: state?.data?.projects ||draftData?.individualsData?.professionalExperiennce?.projects|| "",
    quote: state?.data?.quote ||  draftData?.individualsData?.quote||"",
    individualMediaFeature: state?.data?.individualMediaFeature || draftData?.individualsData?.media?.individualMediaFeature||"",
    individualSocialMedialinks: state?.data?.individualSocialMedialinks ||draftData?.individualsData?.media?.individualSocialMedialinks|| "",
  });


  const [categorydata, setCategoryData] = useState(state?.categorydata 
  );
  const [subCategoryData, setSubCategoryData] = useState(
    state?.subCategoryData
  );
  console.log(categorydata, "individualData");
  const [publicationIds, setPublicationIds] = useState( 
    state?.draftData?.publications ||
      state?.selectedPublicationIds ||
      state?.publicationIds ||
      
      []
  );

  const [selectedOption, setSelectedOption] = useState(
    state?.options ||draftData?.articleBy|| "company"
  );
  const [bannerImgUpload, setBannerImgUpload] = useState(
    state?.data?.bannerImgUpload || state?.photo || draftData?.photos[0]||""
  );

  console.log(files, "filesfilesfiles");

  const [creditImgUpload, setCreditImgUpload] = useState(
    state?.draftData?.creditImage || state?.creditImgUpload || ""
  );

  const [fileType, setFileType] = useState("");
  console.log(fileType, "fileType");

  const [ckEditorContent, setCkEditorContent] = useState("");
  console.log(ckEditorContent,"ckEditorContent");
  
  const htmlConetent = useConvertMarkDownHtml(
    draftData?.description
    // || state?.ckEditorContent
  );


  // const [photo, setPhotoUpload] = useState(state?.data?.photo || "");
  const [key, setKey] = useState(Math.random());
  const [key1, setKey1] = useState(Math.random());
  const [isDivVisible, setIsDivVisible] = useState(false);
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  // show only one toast at one time
  const showToast = (msg) => {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error(msg);
    }
  };

  //closeModal
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  // open modal
  const handleOpenModal = (flag) => {
    setModalDetail({
      show: true,
      flag: flag,
      type: flag,
    });
    setKey(Math.random());
  };

  // Function to handle radio button change
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  // Function to get file extension from a URL
  const getFileExtension = (url) => {
    const extension = url.split(".").pop().toLowerCase();
    return extension;
  };

  // Assuming `filePath` contains the URL you provided
  const filePath = files;
  const fileTypes = getFileExtension(filePath);
console.log(fileTypes,"fileTypes");

  //API for get all category...............
  const getAllCategories = () => {
    let params = {
      flag: "genre",
    };
    dispatch(
      articleCategory({
        ...params,
        cb(res) {
          if (res?.data) {
            setCategory(res?.data?.data?.data);
          }
        },
      })
    );
  };

  useEffect(() => {
    if (htmlConetent) {
      setCkEditorContent(htmlConetent);
    }
  }, [htmlConetent]);

  useEffect(() => {
    const stopLoader = setTimeout(() => {
      setIsLoading(false);
    }, 2000);
    return () => {
      clearTimeout(stopLoader);
    };
  }, []);

  const handleAddPublication = (val) => {
    setPublicationIds((prevIds) => {
      // Check if the object with the same ID already exists in the array
      const index = prevIds.findIndex((item) => item._id === val?._id);
      if (index !== -1) {
        // If it's already in the array, remove it
        return prevIds.filter((item) => item._id !== val?._id);
      } else {
        // If it's not in the array, add it
        return [
          ...prevIds,
          {
            _id: val?._id,
            value: val?.name,
            publicationLogo: val?.publicationLogo,
            amount: val?.amount,
          },
        ];
      }
    });
  };

  // FUNCTION FOR CHNAGE CATEGORY AND UPDATE CATEGORY
  const handleCategoryChange = (id) => {
    // Handle empty category selection
    if (id === "") {
      setCategoryData("");
    } else {
      const selectedCategory = category.find((cat) => cat._id === id);
      if (selectedCategory) {
        setCategoryData(selectedCategory);
      }
    }
  };

  // Set initial category data from draftData
useEffect(() => {
  if (draftData?.categoryId) {
    const initialCategory = category.find((cat) => cat._id === draftData.categoryId);
    if (initialCategory) {
      setCategoryData(initialCategory);
    }
  }
}, [draftData, category]);

  /**
   *
   * @param {*} file
   */

  // function for get crop image modal data through callback
  const handleChange = (file) => {
    props?.uploadImageHandle(file);
  };

  //onDrop AND UPLOAD IMAGE
  const handleDrop = (setPhoto, isImage,setLoader) => (acceptedFiles) => {
    setPhotoUploadUrl(acceptedFiles);
    if (acceptedFiles.length === 0) {
      showToast("Please choose a file");
      return;
    }
    const fileType = acceptedFiles[0].type.split("/")[1];
    // Only set fileType if isImage is false
    if (!isImage) {
      setFileType(fileType);
    }

    // Define allowed types based on whether it's an image or file upload
    const imageAllowedTypes = ["jpg", "jpeg", "png", "svg+xml"];
    const fileAllowedTypes = [
      "msword",
      "pdf",
      "vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];

    // Choose validation based on the flag
    const allowedTypes = isImage ? imageAllowedTypes : fileAllowedTypes;

    if (!allowedTypes.includes(fileType)) {
      showToast(
        isImage
          ? "Please choose only supported image types (JPG, PNG, SVG)"
          : "Please choose only supported file types (DOC, DOCX, PDF)"
      );
      return;
    }

    const imageFile = acceptedFiles[0]; // Only taking the first file
    setLoader(true); 
    let params = {
      images: imageFile,
    };
    dispatch(
      uploadArticleImage({
        ...params,
        cb(res) {
          if (res.status) {
            setLoader(false); 
            setPhoto(res?.data?.data?.[0]);
          }
        },
      })
    );
  };

  /**
   * To pickup file
   */

  // useDropzone hooks with reusable handlers
  const { getRootProps: getRootProps1, getInputProps: getInputProps1 } =
    useDropzone({
      onDrop: handleDrop(setBannerImgUpload, true,setBannerUploadLoader),
      multiple: false,
    });

  const { getRootProps: getRootProps2, getInputProps: getInputProps2 } =
    useDropzone({
      onDrop: handleDrop(setLogo, true,setLogoUploadLoader),
      multiple: false,
    });

  const { getRootProps: getRootProps3, getInputProps: getInputProps3 } =
    useDropzone({
      onDrop: handleDrop(setFiles, false,setFileUploadLoader),
      multiple: false,
    });

  // remove SELECTED PHOTO
  const removeFile = (setPhoto) => {
    setPhoto("");
  };

  // FUNCTION FOR CHNAGE SUB CATEGORY AND UPDATE SUB  CATEGORY
  const handleSubCategoryChange = (id) => {
    // Handle empty subcategory selection
    if (id === "") {
      setSubCategoryData("");
    } else {
      const selectedSubCategory = subCategory.find(
        (subCat) => subCat._id === id
      );
      if (selectedSubCategory) {
        setSubCategoryData(selectedSubCategory);
      }
    }
  };

  const openPublicationSection = () => {
    setIsDivVisible(!isDivVisible);
    setKey1(Math.random());
  };

  /**
   *
   * @param {*} e
   */

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleCompanyFormChange = (e) => {
    console.log(e.target, "e.targete.target");

    const { name, value } = e.target;
    setCompanyData({
      ...companyData,
      [name]: value,
    });
  };

  const handleIndividualsFormChange = (e) => {
    const { name, value } = e.target;
    setIndividualData({
      ...individualData,
      [name]: value,
    });
  };

  const urlTest = new RegExp(
    /^(https?:\/\/)?(www\.)?[a-zA-Z0-9_-]+(\.[a-zA-Z]{2,})+(\/[^\s]*)?$/i
  );

  /**
   * to Delete publicaiton data
   * @param {*} idx
   * @param {*} event
   */
  const handleDeleteItem = (idx, event) => {
    event.stopPropagation(); // Prevent event from propagating to parent
    const newData = [...publicationIds]; // Create a copy of the array
    newData.splice(idx, 1); // Remove the item at index idx
    setPublicationIds(newData); // Update the state with the new array
  };

  /**
   *
   * @param {*} e
   * @returns navigates to article detail page
   */

  const veiwArticleDetails = (e) => {
    e.preventDefault();

    let meargeData = {};
    if (selectedOption === "company") {
      meargeData = {
        ...data, // Existing data values
        ...companyData, // Merged companyData values
      };
    }
    if (selectedOption === "individual") {
      meargeData = {
        ...data, // Existing data values
        ...individualData, // Merged companyData values
      };
    }

    if(selectedOption==="company"){
      if (!companyData?.companyName) {
        showToast("Please enter brand or company name");
        return;
      } else if (!companyData?.industry) {
        showToast("Please select industry or field");
        return;
      } else if (!companyData?.companyWebsite) {
        showToast("Please enter company website");
        return;
      } else if (
        companyData?.companyWebsite &&
        !urlTest.test(companyData?.companyWebsite)
      ) {
        showToast("Please enter valid company website");
        return;
      } else if (!companyData?.briefOverview) {
        showToast("Please enter brief overview");
        return;
      } else if (!companyData?.achievements) {
        showToast("Please enter achievements");
        return;
      } else if (!companyData?.featuredProduct) {
        showToast("Please enter featured product");
        return;
      } else if (!companyData?.sellingPoints) {
        showToast("Please enter unique selling points");
        return;
      } else if (!companyData?.optionalQuote) {
        showToast("Please enter optional customer or partner quotes");
        return;
      } else if (!companyData?.companyMediaFeatures) {
        showToast("Please enter media features");
        return;
      } else if (!companyData?.partnerhships) {
        showToast("Please enter notable partnerhships");
        return;
      } else if (!companyData?.companySocialMedialinks) {
        showToast("Please enter company social media presence");
        return;
      } 
      // else if (
      //   companyData?.companySocialMedialinks &&
      //   !urlTest.test(companyData?.companySocialMedialinks)
      // ) {
      //   showToast("Please enter valid social media presence link");
      //   return;
      // }
       else if (!companyData?.geographic) {
        showToast("Please enter geographic focus");
        return;
      } 
      
    }
    else{
      if (!individualData?.fullName) {
        showToast("Please enter full name");
        return;
      } else if (!individualData?.professionalTitle) {
        showToast("Please enter professional title");
        return;
      } else if (!individualData?.industry) {
        showToast("Please enter industry");
        return;
      } else if (!individualData?.individualWebsite) {
        showToast("Please enter website");
        return;
      } else if (!individualData?.accomplishment) {
        showToast("Please enter key accomplishments");
        return;
      } else if (!individualData?.philosophy) {
        showToast("Please enter personal values or philosophy");
        return;
      } else if (!individualData?.bio) {
        showToast("Please enter brief overview");
        return;
      } else if (!individualData?.projects) {
        showToast("Please enter major projects or contributions");
        return;
      } else if (!individualData?.currentPosition) {
        showToast("Please enter current role or position");
        return;
      } else if (!individualData?.individualMediaFeature) {
        showToast("Please enter media features or recognition");
        return;
      } else if (!individualData?.individualSocialMedialinks) {
        showToast("Please enter social media links");
        return;
      } 
      
    }

    
     if (!data?.mainMessage) {
      showToast("Please enter main message");
      return;
    } else if (!data?.highlights) {
      showToast("Please enter highlights");
      return;
    } else if (!data?.desiredOutcomes) {
      showToast("Please enter desired outcome");
      return;
    } else if (!data?.contactInfo) {
      showToast("Please enter contact information");
      return;
    } else if (!data?.tone) {
      showToast("Please select preferred tone");
      return;
    } else if (!data?.style) {
      showToast("Please select contact writing style");
      return;
    } else if (!data?.additionalNote) {
      showToast("Please enter contact special instructions");
      return;
    } else if (!data?.purpose) {
      showToast("Please enetr purpose of the article");
      return;
    } else if (!data?.targetAudience) {
      showToast("Please enetr target audience");
      return;
    }  
    else if (!categorydata  && !draftData?.categoryId) {
      showToast("Please select Genre");
      return;

      
    } 
    // else if (
    //   data?.links && !urlTest?.test(data?.links)
    // ) {
    //   showToast("Please enter valid link");
    //   return;
    // }
    
    
    
    
     else if (!data?.title) {
      showToast("Title is required");
      return;
    } else if (!draftData &&!data?.articleUrl) {
      showToast("Url is required");
      return;
    } else if (publicationIds?.length < 1) {
      showToast("Please select publication");
      return;
    } else if (bannerImgUpload.length < 1) {
      showToast("Please select feature image");
      return;
    }
    navigate("/article-details", {
      state: {
        selectedPublicationDetails: {
          publicationIds: publicationIds,
          categorydata: categorydata,
          subCategoryData: subCategoryData,
          options: selectedOption,
          data: meargeData,
          photo: bannerImgUpload,
          creditImgUpload: creditImgUpload,
          type: "articleAI",
          draftData:draftData,
          ckEditorContent:ckEditorContent
          
        },
        
      },
    });
  };

  useEffect(() => {
    if (categorydata?._id) handleCategoryChange(categorydata?._id);
  }, [categorydata?._id]);

  useEffect(() => {
    getAllCategories();
  }, []);

  // useEffect(() => {
  //   if (selectedOption === "company") {
  //     const mergedData = {
  //       ...data, // Existing data values
  //       ...companyData, // Merged companyData values
  //     };

  //     setData(mergedData); // Update the `data` state
  //   } else {
  //     const mergedData = {
  //       ...data, // Existing data values
  //       ...individualData, // Merged companyData values
  //     };

  //     setData(mergedData);
  //   }
  // }, [selectedOption]);

  return (
    <>
      <div className="content-wrapper">
        <div className="innerHead">
          <img
            src={images.arrowLink}
            className="img-fluid pointCursor"
            alt="arrowLink image"
            onClick={() => {
              if (state?.type === "articleAI") {
                navigate("/select-publication", {
                  state,
                });
              } else {
                navigate(-1);
              }
            }}
          />
          <h2 className="innerHeadText">Article Creation Through AI</h2>
        </div>
        <div className="articleForm">
          <div className="articleRadio">
            <div className="subRadio">
              <input
                type="radio"
                id="test1"
                name="radio-group"
                value="company"
                checked={selectedOption === "company"}
                onChange={handleOptionChange}
              />
              <label htmlFor="test1" className="labelTxt">
                Company
              </label>
            </div>
            <div className="subRadio">
              <input
                type="radio"
                id="test2"
                name="radio-group"
                value="individual"
                checked={selectedOption === "individual"}
                onChange={handleOptionChange}
              />
              <label htmlFor="test2" className="labelTxt">
                Individual
              </label>
            </div>
          </div>
          {selectedOption === "company" ? (
            <>
              <div className="row">
                <h3 for="Category1" className="formFields_Head text-left mt-0">
                  Basic Information
                </h3>
                <div className="col-lg-6 mt-3">
                  <div className="signupField">
                    <label for="entityName1" className="input_Label">
                      Brand/Company Name
                    </label>
                    <input
                      type="Text"
                      className="form-control nameControl"
                      placeholder="What is the company name?"
                      name="companyName"
                      value={companyData.companyName}
                      onChange={handleCompanyFormChange}
                    />
                  </div>
                </div>
                <div className="col-lg-6 mt-3">
                  <div className="signupField">
                    <label for="news" className="input_Label">
                      Industry/Field
                    </label>
                  
                    <input
                      type="Text"
                      className="form-control nameControl"
                      placeholder="What is the company name?"
                      value={companyData?.industry}
                      name="industry"
                      onChange={handleCompanyFormChange}
                    />
                  </div>
                </div>
                <div className="col-lg-6 mt-3">
                  <div className="signupField">
                    <label for="news" className="input_Label">
                      Website
                    </label>
                    <input
                      type="Text"
                      className="form-control nameControl"
                      placeholder="website URL"
                      name="companyWebsite"
                      value={companyData?.companyWebsite}
                      onChange={handleCompanyFormChange}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <h3 for="Category" className="formFields_Head text-left ">
                  Company Overview
                </h3>
                <div className="col-lg-6 mt-3">
                  <div className="signupField">
                    <label for="entityName" className="input_Label">
                      Brief Overview
                    </label>
                    <textarea
                      className="form-control customInput"
                      type="text"
                      placeholder="Write Overview here.."
                      rows={4}
                      name="briefOverview"
                      value={companyData?.briefOverview}
                      onChange={handleCompanyFormChange}
                    />
                  </div>
                </div>
                <div className="col-lg-6 mt-3">
                  <div className="signupField">
                    <label for="news" className="input_Label">
                      Founding Story (Optional)
                    </label>
                    <textarea
                      className="form-control customInput"
                      type="text"
                      placeholder="Write Story here.."
                      rows={4}
                      name="foundHistory"
                      value={companyData?.foundHistory}
                      onChange={handleCompanyFormChange}
                    />
                  </div>
                </div>
                <div className="col-lg-6 mt-3">
                  <div className="signupField">
                    <label for="news" className="input_Label">
                      Notable Achievements
                    </label>
                    <input
                      type="Text"
                      className="form-control nameControl"
                      placeholder="What is the achievements of the article?"
                      name="achievements"
                      value={companyData?.achievements}
                      onChange={handleCompanyFormChange}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <h3 for="Category" className="formFields_Head text-left ">
                  Products/Services
                </h3>
                <div className="col-lg-6 mt-3">
                  <div className="signupField">
                    <label for="entityName" className="input_Label">
                      Featured Products/Services
                    </label>
                    <input
                      type="Text"
                      className="form-control nameControl"
                      placeholder="What is the featured services?"
                      name="featuredProduct"
                      value={companyData?.featuredProduct}
                      onChange={handleCompanyFormChange}
                    />
                  </div>
                </div>
                <div className="col-lg-6 mt-3">
                  <div className="signupField">
                    <label for="news" className="input_Label">
                      Unique Selling Points (USPs)
                    </label>
                    <input
                      type="Text"
                      className="form-control nameControl"
                      placeholder=" Unique Selling Points"
                      name="sellingPoints"
                      value={companyData?.sellingPoints}
                      onChange={handleCompanyFormChange}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <h3 for="Category" className="formFields_Head text-left">
                  Executive Leadership Quotes
                </h3>
                <div className="col-lg-6 mt-3">
                  <div className="signupField">
                    <label for="entityName" className="input_Label">
                      CEO/Founder Quotes (Optional)
                    </label>
                    <input
                      type="Text"
                      className="form-control nameControl"
                      placeholder="What is the CEO/Founder quotes?"
                      name="founderQuote"
                      value={companyData?.founderQuote}
                      onChange={handleCompanyFormChange}
                    />
                  </div>
                </div>
                <div className="col-lg-6 mt-3">
                  <div className="signupField">
                    <label for="news" className="input_Label">
                      Optional Customer/Partner Quotes
                    </label>
                    <input
                      type="Text"
                      className="form-control nameControl"
                      placeholder="What is the customer quotes"
                      name="optionalQuote"
                      value={companyData?.optionalQuote}
                      onChange={handleCompanyFormChange}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <h3 for="Category" className="formFields_Head text-left">
                  Media or Social Proof
                </h3>
                <div className="col-lg-6 mt-3">
                  <div className="signupField">
                    <label for="entityName" className="input_Label">
                      Previous Media Features
                    </label>
                    <input
                      type="Text"
                      className="form-control nameControl"
                      placeholder="List any media coverage"
                      name="companyMediaFeatures"
                      value={companyData?.companyMediaFeatures}
                      onChange={handleCompanyFormChange}
                    />
                  </div>
                </div>
                <div className="col-lg-6 mt-3">
                  <div className="signupField">
                    <label for="news" className="input_Label">
                      Notable Partnerships
                    </label>
                    <input
                      type="Text"
                      className="form-control nameControl"
                      placeholder="List of key partnerships"
                      name="partnerhships"
                      value={companyData?.partnerhships}
                      onChange={handleCompanyFormChange}
                    />
                  </div>
                </div>
                <div className="col-lg-6 mt-3">
                  <div className="signupField">
                    <label for="news" className="input_Label">
                      Social Media Presence
                    </label>
                    <input
                      type="Text"
                      className="form-control nameControl"
                      placeholder=" Add URL"
                      name="companySocialMedialinks"
                      value={companyData?.companySocialMedialinks}
                      onChange={handleCompanyFormChange}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <h3 for="Category" className="formFields_Head text-left">
                  Targeted Media Outlet/Industry Focus
                </h3>
                <div className="col-lg-6 mt-3">
                  <div className="signupField">
                    <label for="entityName" className="input_Label">
                      Preferred Media Outlet (Optional)
                    </label>
                    <input
                      type="Text"
                      className="form-control nameControl"
                      placeholder="What is specific outlets of article?"
                      name="preferredMedia"
                      value={companyData?.preferredMedia}
                      onChange={handleCompanyFormChange}
                    />
                  </div>
                </div>
                <div className="col-lg-6 mt-3">
                  <div className="signupField">
                    <label for="news" className="input_Label">
                      Geographic Focus
                    </label>
                    <input
                      type="Text"
                      className="form-control nameControl"
                      placeholder="What is geographic focus of article?"
                      name="geographic"
                      value={companyData?.geographic}
                      onChange={handleCompanyFormChange}
                    />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="row">
                <h3 for="Category2" className="formFields_Head text-left mt-0">
                  Basic Information
                </h3>
                <div className="col-lg-6 mt-3">
                  <div className="signupField">
                    <label for="fullName" className="input_Label">
                      Full Name
                    </label>
                    <input
                      id="fullName"
                      type="Text"
                      className="form-control nameControl"
                      placeholder="What is the full name?"
                      name="fullName"
                      value={individualData.fullName}
                      onChange={handleIndividualsFormChange}
                    />
                  </div>
                </div>
                <div className="col-lg-6 mt-3">
                  <div className="signupField">
                    <label for="entityName" className="input_Label">
                      Professional Title
                    </label>
                    <input
                      type="Text"
                      className="form-control nameControl"
                      placeholder="What is the professional title?"
                      name="professionalTitle"
                      value={individualData?.professionalTitle}
                      onChange={handleIndividualsFormChange}
                    />
                  </div>
                </div>
                {/* <div className="col-lg-6 mt-3">
                  <div className="signupField">
                    <label for="news" className="input_Label">
                      Industry/Field
                    </label>
                    <FormControl>
                      <Select
                        className="customSelect"
                        name="industry"
                        value={individualData?.industry}
                        onChange={handleIndividualsFormChange}
                        label="Category"
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        sx={{
                          fontFamily: "Poppins",
                          ".MuiSvgIcon-root": {
                            color: "#fff", // Change this to the desired color
                          },
                          fontStyle: "normal",
                        }}
                        MenuProps={{
                          PaperProps: {
                            sx: {
                              borderRadius: "12px",
                              border: "1px solid #FFFFFF4D",
                              background: "#616161",
                              color: "#ffffff",
                              fontSize: "16px",
                              fontWeight: "500",
                              marginTop: "5px",
                              " & .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root":
                                {
                                  color: "#ffffff",
                                  fontSize: "16px",
                                  fontWeight: "500",
                                  fontFamily: "inherit",
                                  marginTop: "3px",
                                  "&.Mui-selected": {
                                    backgroundColor: "#757575",
                                  },
                                  "&:hover": {
                                    backgroundColor: "#757575",
                                  },
                                  "&.Mui-selected:hover": {
                                    backgroundColor: "#757575",
                                  },
                                },
                            },
                          },
                        }}
                      >
                        <MenuItem value={companyData?.industry} disabled>
                          Select Industry
                        </MenuItem>

                        <MenuItem value="Retail" key="">
                          Retail
                        </MenuItem>
                        <MenuItem value="Technology" key="">
                          Technology
                        </MenuItem>
                        <MenuItem value="Finance" key="">
                          Finance
                        </MenuItem>
                     
                      </Select>
                    </FormControl>
                  </div>
                </div> */}
                <div className="col-lg-6 mt-3">
                  <div className="signupField">
                    <label for="news" className="input_Label">
                      Industry/Field
                    </label>
                  
                    <input
                      type="Text"
                      className="form-control nameControl"
                      placeholder="What is the company name?"
                      value={individualData?.industry}
                      name="industry"
                      onChange={handleIndividualsFormChange}
                    />
                  </div>
                </div>
                <div className="col-lg-6 mt-3">
                  <div className="signupField">
                    <label for="news" className="input_Label">
                      Website
                    </label>
                    <input
                      type="Text"
                      className="form-control nameControl"
                      placeholder="website URL"
                      name="individualWebsite"
                      value={individualData?.individualWebsite}
                      onChange={handleIndividualsFormChange}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <h3 for="Category" className="formFields_Head text-left ">
                  Personal Background Information
                </h3>

                <div className="col-lg-6 mt-3">
                  <div className="signupField">
                    <label for="news" className="input_Label">
                      Key Accomplishments
                    </label>
                    <input
                      type="Text"
                      className="form-control nameControl"
                      placeholder="What is the Key of the article?"
                      name="accomplishment"
                      value={individualData?.accomplishment}
                      onChange={handleIndividualsFormChange}
                    />
                  </div>
                </div>

                <div className="col-lg-6 mt-3">
                  <div className="signupField">
                    <label for="news" className="input_Label">
                      Personal Values or Philosophy
                    </label>
                    <input
                      type="Text"
                      className="form-control nameControl"
                      placeholder="What is the Philosophy of the article?"
                      name="philosophy"
                      value={individualData?.philosophy}
                      onChange={handleIndividualsFormChange}
                    />
                  </div>
                </div>

                <div className="col-lg-6 mt-3">
                  <div className="signupField">
                    <label for="entityName" className="input_Label">
                      Brief Overview
                    </label>
                    <textarea
                      className="form-control customInput"
                      type="text"
                      placeholder="Write Overview here.."
                      rows={4}
                      name="bio"
                      value={individualData?.bio}
                      onChange={handleIndividualsFormChange}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <h3 for="Category" className="formFields_Head text-left ">
                  Professional Experience
                </h3>

                <div className="col-lg-6 mt-3">
                  <div className="signupField">
                    <label for="news" className="input_Label">
                      Previous Notable Roles (Optional)
                    </label>
                    <input
                      type="Text"
                      className="form-control nameControl"
                      placeholder="previous key role?"
                      name="previousRole"
                      value={individualData?.previousRole}
                      onChange={handleIndividualsFormChange}
                    />
                  </div>
                </div>

                <div className="col-lg-6 mt-3">
                  <div className="signupField">
                    <label for="news" className="input_Label">
                      Major Projects/Contributions
                    </label>
                    <input
                      type="Text"
                      className="form-control nameControl"
                      placeholder="major projects?"
                      name="projects"
                      value={individualData?.projects}
                      onChange={handleIndividualsFormChange}
                    />
                  </div>
                </div>

                <div className="col-lg-6 mt-3">
                  <div className="signupField">
                    <label for="entityName" className="input_Label">
                      Current Role/Position
                    </label>
                    <textarea
                      className="form-control customInput"
                      type="text"
                      placeholder="Write text here.."
                      rows={4}
                      name="currentPosition"
                      value={individualData?.currentPosition}
                      onChange={handleIndividualsFormChange}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <h3 for="Category" className="formFields_Head text-left">
                  Quotes
                </h3>
                <div className="col-lg-6 mt-3">
                  <div className="signupField">
                    <label for="entityName" className="input_Label">
                      Personal Quote (Optional)
                    </label>
                    <input
                      type="Text"
                      className="form-control nameControl"
                      placeholder="What is the personal quotes?"
                      name="quote"
                      value={individualData?.quote}
                      onChange={handleIndividualsFormChange}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <h3 for="Category" className="formFields_Head text-left">
                  Media or Social Proof
                </h3>
                <div className="col-lg-6 mt-3">
                  <div className="signupField">
                    <label for="entityName" className="input_Label">
                      Media Features/Recognition
                    </label>
                    <input
                      type="Text"
                      className="form-control nameControl"
                      placeholder="List any media features"
                      name="individualMediaFeature"
                      value={individualData?.individualMediaFeature}
                      onChange={handleIndividualsFormChange}
                    />
                  </div>
                </div>

                <div className="col-lg-6 mt-3">
                  <div className="signupField">
                    <label for="news" className="input_Label">
                    Social Media Link
                    </label>
                    <input
                      type="Text"
                      className="form-control nameControl"
                      placeholder="Social media links"
                      name="individualSocialMedialinks"
                      value={individualData?.individualSocialMedialinks}
                      onChange={handleIndividualsFormChange}
                    />
                  </div>
                </div>
              </div>
            </>
          )}

          <div className="row">
            <h3 for="Category" className="formFields_Head text-left">
              Key Messages
            </h3>
            <div className="col-lg-6 mt-3">
              <div className="signupField">
                <label for="entityName" className="input_Label">
                  Main Message/Theme
                </label>
                <input
                  type="Text"
                  className="form-control nameControl"
                  placeholder="What is the main message of the article?"
                  name="mainMessage"
                  value={data?.mainMessage}
                  onChange={handleFormChange}
                />
              </div>
            </div>
            <div className="col-lg-6 mt-3">
              <div className="signupField">
                <label for="news" className="input_Label">
                  Top 3 Highlights
                </label>
                <input
                  type="Text"
                  className="form-control nameControl"
                  placeholder="Top 3 highlights"
                  name="highlights"
                  value={data?.highlights}
                  onChange={handleFormChange}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <h3 for="Category" className="formFields_Head text-left">
              Call to Action
            </h3>
            <div className="col-lg-6 mt-3">
              <div className="signupField">
                <label for="entityName" className="input_Label">
                  Desired Outcome
                </label>
                <input
                  type="Text"
                  className="form-control nameControl"
                  placeholder="What action should readers take?"
                  name="desiredOutcomes"
                  value={data?.desiredOutcomes}
                  onChange={handleFormChange}
                />
              </div>
            </div>
            <div className="col-lg-6 mt-3">
              <div className="signupField">
                <label for="news" className="input_Label">
                  Contact Information
                </label>
                <input
                  type="Text"
                  className="form-control nameControl"
                  placeholder="Provide contact details for interested media"
                  name="contactInfo"
                  value={data?.contactInfo}
                  onChange={handleFormChange}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <h3 for="Category" className="formFields_Head text-left ">
              Tone and Style Preferences
            </h3>
            <div className="col-lg-6 mt-3">
              <div className="signupField">
                <label for="entityName" className="input_Label">
                  Preferred Tone
                </label>
              
                <input
                  type="Text"
                  className="form-control nameControl"
                  placeholder="Provide contact details for interested media"
                  name="tone"
                  value={data?.tone}
                  onChange={handleFormChange}
                />
              </div>
            </div>

            <div className="col-lg-6 mt-3">
              <div className="signupField">
                <label for="entityName" className="input_Label">
                  Writing Style
                </label>
               
                <input
                  type="Text"
                  className="form-control nameControl"
                  placeholder="Provide contact details for interested media"
                  name="style"
                  value={data?.style}
                  onChange={handleFormChange}
                />
              </div>
            </div>
            
          </div>

          <div className="row">
            <h3 for="Category" className="formFields_Head text-left ">
              Attachments/Media
            </h3>
            <div className="col-xl-6">
              <div className="mt-3">
                <label for="news" className="input_Label">
                  Upload Files (Optional)
                </label>
                <div className="form-group col-md-12">
                  <>
                    {logo?.length > 0 ? (
                      ""
                    ) : (
                      <div className="uploadImgeboxNewArticle writeArticle_">
                        {webSelector?.loading ||logoUploadLoader ? (
                          <div className="row">
                            <div className="col-sm-12 text-center pt-5 pb-5">
                              <div
                                className="spinner-grow text-success"
                                role="status"
                              ></div>
                            </div>
                          </div>
                        ) : (
                          <div {...getRootProps2({ className: "dropzone" })}>
                            <input {...getInputProps2()} />

                            <img
                              src={images.upload}
                              alt="uploadIcon"
                              className="img-fluid  articleupload_ mb-2"
                            />

                            <p className="uploadbtntext">
                              Drag image here or
                              <span className="ColoredText"> Browse </span>
                            </p>
                            <p className="uploadText mt-2">
                              Supports JPG, PNG, SVG
                            </p>
                            <p className="uploadText mt-2">
                              Upload Image with Resolution Min 1200 x 600
                            </p>
                          </div>
                        )}
                      </div>
                    )}
                    {logo && (
                      <aside>
                        <ul className="uploadedArticle">
                          <div className="uploadImgeboxNewArticle ">
                            <div className="position-relative uploadArticle_UploadImage">
                              <img
                                className="w3-round uploadfileIcon "
                                src={logo}
                              />
                              <button
                                className="deleteUpload"
                                onClick={() => removeFile(setLogo)}
                              >
                                <img
                                  src={images.DeleteIcon}
                                  alt="delet image"
                                  className="img-fluid deletIcon"
                                />
                              </button>
                            </div>
                          </div>
                        </ul>
                      </aside>
                    )}
                  </>
                </div>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="mt-3">
                <label for="news" className="input_Label">
                  
                </label>
                <div className="form-group col-md-12">
                  <>
                    {files?.length > 0 ? (
                      ""
                    ) : (
                      <div className="uploadImgeboxNewArticle writeArticle_">
                        {webSelector?.loading ||fileUploadLoader? (
                          <div className="row">
                            <div className="col-sm-12 text-center pt-5 pb-5">
                              <div
                                className="spinner-grow text-success"
                                role="status"
                              ></div>
                            </div>
                          </div>
                        ) : (
                          <div {...getRootProps3({ className: "dropzone" })}>
                            <input {...getInputProps3()} />

                            <img
                              src={images.upload}
                              alt="uploadIcon"
                              className="img-fluid  articleupload_ mb-2"
                            />

                            <p className="uploadbtntext">
                              Drag file here or
                              <span className="ColoredText"> Browse </span>
                            </p>
                            <p className="uploadText mt-2">
                              Supports DOC, DOCX, PDF
                            </p>
                            {/* <p className="uploadText mt-2">
                              Upload Image with Resolution Min 1200 x 600
                            </p> */}
                          </div>
                        )}
                      </div>
                    )}
                    {files && (
                      <aside>
                        <ul className="uploadedArticle">
                          <div className="uploadImgeboxNewArticle ">
                            <div className="position-relative uploadArticle_UploadImage">
                              <img
                                className="w3-round uploadfileIcon "
                                src={
                                  fileTypes === "pdf"
                                    ? images.PDFImage
                                    : fileTypes === "msword" ||fileTypes === "doc" ||  fileTypes === "docx"||
                                      fileTypes === "vnd.openxmlformats-officedocument.wordprocessingml.document"
                                    ? images.DOCImage
                                    : files
                                }
                              />
                              <button
                                className="deleteUpload"
                                onClick={() => removeFile(setFiles)}
                              >
                                <img
                                  src={images.DeleteIcon}
                                  alt="delet image"
                                  className="img-fluid deletIcon"
                                />
                              </button>
                            </div>
                          </div>
                        </ul>
                      </aside>
                    )}
                  </>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <h3 for="Category" className="formFields_Head text-left">
              Additional Notes{" "}
            </h3>

            <div className="col-lg-12 mt-3">
              <div className="signupField">
                <label for="news" className="input_Label">
                  Special Instructions
                </label>
                <textarea
                  className="form-control customInput"
                  type="text"
                  placeholder="Write Overview here.."
                  rows={4}
                  name="additionalNote"
                  value={data?.additionalNote}
                  onChange={handleFormChange}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <h3 for="Category" className="formFields_Head text-left">
              Article Purpose/Goal
            </h3>
            <div className="col-lg-6 mt-3">
              <div className="signupField">
                <label for="entityName" className="input_Label">
                  Purpose of the Article
                </label>
                <input
                  type="Text"
                  className="form-control nameControl"
                  placeholder="What is the purpose of the article?"
                  name="purpose"
                  value={data?.purpose}
                  onChange={handleFormChange}
                />
              </div>
            </div>
            <div className="col-lg-6 mt-3">
              <div className="signupField">
                <label for="news" className="input_Label">
                  Target Audience
                </label>
                <input
                  type="Text"
                  className="form-control nameControl"
                  placeholder="Target Audience"
                  name="targetAudience"
                  value={data?.targetAudience}
                  onChange={handleFormChange}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <h3 for="Category" className="formFields_Head text-left ">
              Headline/Title
            </h3>
            <div className="col-lg-6 mt-3">
              <div className="signupField">
                <label for="entityName" className="input_Label">
                  Suggested Headline (Optional)
                </label>
                <input
                  type="Text"
                  className="form-control nameControl"
                  placeholder="What is the Headline of articles?"
                  name="headline"
                  value={data?.headline}
                  onChange={handleFormChange}
                />
              </div>
            </div>
          </div>


          

          <div className="row">
            <div className="col-lg-6">
              <div className="selectBox mt-4">
                <label for="Category" className="input_Label">
                  Select Genre
                </label>
                <FormControl>
                  <Select
                    className="customSelect"
                    value={categorydata?._id || categorydata ||draftData?.categoryId}
                    onChange={(e) => {
                      handleCategoryChange(e.target.value, category);
                      // setPublicationIds([])
                    }}
                    label="Category"
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    sx={{
                      fontFamily: "Poppins",
                      ".MuiSvgIcon-root": {
                        color: "#fff", // Change this to the desired color
                      },
                      fontStyle: "normal",
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          borderRadius: "12px",
                          border: "1px solid #FFFFFF4D",
                          background: "#616161",
                          color: "#ffffff",
                          fontSize: "16px",
                          fontWeight: "500",
                          marginTop: "5px",
                          " & .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root":
                            {
                              color: "#ffffff",
                              fontSize: "16px",
                              fontWeight: "500",
                              fontFamily: "inherit",
                              marginTop: "3px",
                              "&.Mui-selected": {
                                backgroundColor: "#757575",
                              },
                              "&:hover": {
                                backgroundColor: "#757575",
                              },
                              "&.Mui-selected:hover": {
                                backgroundColor: "#757575",
                              },
                            },
                        },
                      },
                    }}
                  >
                    <MenuItem
                      value={categorydata?._id || categorydata}
                      disabled
                    >
                      Select Genre
                    </MenuItem>

                    {category.map((category, index) => (
                      <MenuItem value={category?._id} key={index}>
                        {category?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="col-lg-6 mt-4">
              <div className="signupField">
                <label for="news" className="input_Label">
                  Title
                </label>
                <input
                  type="text"
                  className="form-control nameControl"
                  placeholder="Title"
                  name="title"
                  value={data?.title}
                  onChange={handleFormChange}
                />
              </div>
            </div>
            {/* <div className="col-lg-6">
              <div className="selectBox mt-4">
                <FormControl>
                  <Select
                    className="customSelect"
                    value={subCategoryData?._id || subCategoryData}
                    onChange={(e) =>{
                      handleSubCategoryChange(e.target.value, subCategory)
                      setPublicationIds([])
                    }}

                    
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    sx={{
                      fontFamily: "Poppins",
                      ".MuiSvgIcon-root": {
                        color: "#fff", // Change this to the desired color
                      },
                      fontStyle: "normal",
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          borderRadius: "12px",
                          border: "1px solid #FFFFFF4D",
                          backgroundColor: "#616161",
                          color: "#ffffff",
                          fontSize: "16px",
                          fontWeight: "500",
                          marginTop: "5px",
                          " & .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root":
                            {
                              color: "#ffffff",
                              fontSize: "16px",
                              fontWeight: "500",
                              fontFamily: "inherit",
                              marginTop: "3px",
                              background: "#616161",
                              "&.Mui-selected": {
                                backgroundColor: "#757575",
                              },
                              "&:hover": {
                                backgroundColor: "#757575",
                              },
                              "&.Mui-selected:hover": {
                                backgroundColor: "#757575",
                              },
                            },
                        },
                      },
                    }}
                  >
                    <MenuItem
                      value={subCategoryData?._id || subCategoryData}
                      disabled
                    >
                      Select Type
                    </MenuItem>
                    {subCategory.map((subCategory) => (
                      <MenuItem value={subCategory?._id}>
                        {subCategory?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div> */}

            {draftData?
            <div className=" articleeditor_ mt-3 position-relative">
            {isLoading ? (
                            <div className="loaderOuter d-flex justify-content-center text-light flex-column align-items-center">
                              <div
                                className="spinner-grow text-light"
                                role="status"
                              ></div>
                            </div>
                          ) : (
            <CKEditorWithRef
            initData={ckEditorContent}
            // ref={editorRef}
            config={{
              extraPlugins: ["easyimage"],
              versionCheck: false,
              removePlugins: [
                "ImageUpload",
                "MediaEmbed",
                "Table",
                "TableToolbar",
              ],
              toolbarLocation: "top",
              removeButtons:
                "Table,TableToolbar,Anchor,HorizontalRule,Blockquote",
              toolbar: [
                {
                  name: "document",
                  items: ["Undo", "Redo"],
                },
                { name: "styles", items: ["Format"] },
                {
                  name: "basicstyles",
                  items: [
                    "Bold",
                    "Italic",
                    "Strike",
                    "-",
                    "RemoveFormat",
                  ],
                },
                {
                  name: "paragraph",
                  items: ["NumberedList", "BulletedList"],
                },
                {
                  name: "links",
                  items: ["Link", "Unlink"],
                },
                {
                  name: "insert",
                  items: ["EasyImageUpload"],
                },
              ],

              cloudServices_uploadUrl:
                "https://33333.cke-cs.com/easyimage/upload/",
              cloudServices_tokenUrl:
                "https://33333.cke-cs.com/token/dev/ijrDsqFix838Gh3wGO3F77FSW94BwcLXprJ4APSp3XQ26xsUHTi0jcb1hoBt",
              easyimage_styles: {
                gradient1: {
                  group: "easyimage-gradients",
                  attributes: {
                    className: "easyimage-gradient-1",
                  },
                  label: "Blue Gradient",
                  icon: "https://ckeditor.com/docs/ckeditor4/4.24.0-lts/examples/assets/easyimage/icons/gradient1.png",
                  iconHiDpi:
                    "https://ckeditor.com/docs/ckeditor4/4.24.0-lts/examples/assets/easyimage/icons/hidpi/gradient1.png",
                },
                gradient2: {
                  group: "easyimage-gradients",
                  attributes: {
                    className: "easyimage-gradient-2",
                  },
                  label: "Pink Gradient",
                  icon: "https://ckeditor.com/docs/ckeditor4/4.24.0-lts/examples/assets/easyimage/icons/gradient2.png",
                  iconHiDpi:
                    "https://ckeditor.com/docs/ckeditor4/4.24.0-lts/examples/assets/easyimage/icons/hidpi/gradient2.png",
                },
                noGradient: {
                  group: "easyimage-gradients",
                  attributes: {
                    className: "easyimage-no-gradient",
                  },
                  label: "No Gradient",
                  icon: "https://ckeditor.com/docs/ckeditor4/4.24.0-lts/examples/assets/easyimage/icons/nogradient.png",
                  iconHiDpi:
                    "https://ckeditor.com/docs/ckeditor4/4.24.0-lts/examples/assets/easyimage/icons/hidpi/nogradient.png",
                },
              },
              easyimage_toolbar: [
                "EasyImageFull",
                // "EasyImageSide",
                "EasyImageGradient1",
                "EasyImageGradient2",
                "EasyImageNoGradient",
                "EasyImageAlt",
              ],
              height: 630,
              removeButtons: "PasteFromWord",
              contentsCss: [
                "ckEditor.css",
                ".ckeditor-content-colour { color: #ffffff !important; font-family: sans-serif; font-size: 16px ; font-weight: 400 }",
              ],
              bodyClass: "ckeditor-content-colour",
            }}
            name="editor"
            readOnly={false}
            onChange={(event) => {
              const editor = event.editor;
              const data = editor.getData();
              setCkEditorContent(data);
            }}
            type="classic"
          />)}
          </div>:<>
          
          <div className="col-lg-6 mt-4">
              <div className="signupField">
                <label for="entityName" className="input_Label">
                  Entity Name
                </label>
                <input
                  type="Text"
                  className="form-control nameControl"
                  placeholder="What is the entity name?"
                  name="entityName"
                  value={data?.entityName}
                  onChange={handleFormChange}
                />
              </div>
            </div>
            <div className="col-lg-6 mt-4">
              <div className="signupField">
                <label for="news" className="input_Label">
                  News
                </label>
                <input
                  type="Text"
                  className="form-control nameControl"
                  placeholder="What news do you want to share?"
                  name="news"
                  value={data?.news}
                  onChange={handleFormChange}
                />
              </div>
            </div>
            <div className="col-lg-6 mt-4">
              <div className="signupField">
                <label for="callAction" className="input_Label">
                  Action
                </label>
                <input
                  type="Text"
                  className="form-control nameControl"
                  placeholder="What is the call-to-action?"
                  name="callAction"
                  value={data?.callAction}
                  onChange={handleFormChange}
                />
              </div>
            </div>
            <div className="col-lg-6 mt-4">
              <div className="signupField">
                <label for="news" className="input_Label">
                  Keywords
                </label>
                <input
                  type="Text"
                  className="form-control nameControl"
                  placeholder="Add relevant keywords"
                  name="relevantKeyWords"
                  value={data?.relevantKeyWords}
                  onChange={handleFormChange}
                />
              </div>
            </div>
            <div className="col-lg-6 mt-4">
              <div className="signupField">
                <label for="news" className="input_Label">
                  Add Link
                </label>
                <input
                  type="Text"
                  className="form-control nameControl"
                  placeholder="Add links to include in the article"
                  name="links"
                  value={data?.links}
                  onChange={handleFormChange}
                />
              </div>
            </div>
            
            <div className="col-lg-6 mt-4">
              <div className="signupField">
                <label for="news" className="input_Label">
                  Add URL
                </label>
                <input
                  type="text"
                  className="form-control nameControl"
                  placeholder="Add URL"
                  name="articleUrl"
                  value={data?.articleUrl}
                  onChange={handleFormChange}
                />
              </div>
            </div></>
          }
            
            <div className="col-lg-12 mt-4">
              <div>
                <label for="news" className="input_Label">
                  Publication
                </label>
                <div
                  className={
                    isDivVisible
                      ? "publicationSelect active"
                      : "publicationSelect"
                  }
                  onClick={() => setIsDivVisible((prev) => !prev)}
                  key={key1}
                >
                  {publicationIds && publicationIds.length > 0 ? (
                    <>
                      {publicationIds?.map((value, idx) => {
                        return (
                          <div className="selectPublish" key={idx}>
                            {/* <h6 className="ratingText">{value?.value}</h6> */}
                            <h6 className="ratingText">{value?.value}</h6>

                            {/* <img
                            src={images.selectCross}
                            alt="selectCross img"
                            className="img-fluid pointCursor"
                            onClick={(event) => handleDeleteItem(idx, event)} // Call handleDeleteItem on click
                          /> */}
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <h4 className="authPara">Select Publications </h4>
                  )}
                  {/* <img
                  src={images.selectArrow}
                  alt="selectArrow image"
                  className="img-fluid publishArrow"
                /> */}
                </div>
              </div>
              {/* {isDivVisible && (
                <div
                  className={`target-div ${isDivVisible ? "visible mt-3" : ""}`}
                >
                  <SelectPublication
                    handleAddPublication={handleAddPublication}
                    publicationIds={publicationIds}
                    categorydata={categorydata}
                    subCategoryData={subCategoryData}
                  />
                </div>
              )} */}
            </div>
            <div className="mt-4">
              <label for="news" className="input_Label">
                Feature Image
              </label>
              <div className="form-group col-md-12">
                <>
                  {bannerImgUpload?.length > 0 ? (
                    ""
                  ) : (
                    <div className="uploadImgeboxNewArticle writeArticle_">
                      {webSelector?.loading ||bannerUploadLoader ? (
                        <div className="row">
                          <div className="col-sm-12 text-center pt-5 pb-5">
                            <div
                              className="spinner-grow text-success"
                              role="status"
                            ></div>
                          </div>
                        </div>
                      ) : (
                        <div {...getRootProps1({ className: "dropzone" })}>
                          <input {...getInputProps1()} />

                          <img
                            src={images.upload}
                            alt="uploadIcon"
                            className="img-fluid  articleupload_ mb-2"
                          />

                          <p className="uploadbtntext">
                            Drag image here or
                            <span className="ColoredText"> Browse </span>
                          </p>
                          <p className="uploadText mt-2">
                            Supports JPG, PNG, SVG
                          </p>
                          <p className="uploadText mt-2">
                            Upload Image with Resolution Min 1200 x 600
                          </p>
                        </div>
                      )}
                    </div>
                  )}
                  {bannerImgUpload && (
                    <aside>
                      <ul className="uploadedArticle">
                        <div className="uploadImgeboxNewArticle ">
                          <div className="position-relative uploadArticle_UploadImage">
                            <img
                              className="w3-round uploadfileIcon "
                              src={bannerImgUpload}
                            />
                            <button
                              className="deleteUpload"
                              onClick={() => removeFile(setBannerImgUpload)}
                            >
                              <img
                                src={images.DeleteIcon}
                                alt="delet image"
                                className="img-fluid deletIcon"
                              />
                            </button>
                          </div>
                        </div>
                      </ul>
                    </aside>
                  )}
                </>
              </div>
            </div>
            <div className="col-lg-12 mt-4">
              <div>
                <label for="news" className="input_Label">
                  Credit Image(Optional)
                </label>
                <div className="signupField">
                  <input
                    type="text"
                    className="form-control nameControl"
                    placeholder="Write.."
                    name="title"
                    value={creditImgUpload}
                    onChange={(e) => setCreditImgUpload(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <button
                className="signUpBtn  me-3 mt-3"
                type="button"
                onClick={() => {
                  if (state?.type === "articleAI") {
                    navigate("/select-publication", {
                      state,
                    });
                  } else {
                    navigate(-1);
                  }
                }}
              >
                Back
              </button>

              <button
                className="signUpBtn me-3 mt-3"
                type="button"
                onClick={veiwArticleDetails}
              >
                {draftData?"Next":"Generate"}
              </button>
            </div>
            {/* <div className="col-lg-12 mt-4">
              <button
                type="button"
                className="signUpBtn mt-2"
                onClick={veiwArticleDetails}
              >
                Generate
              </button>
            </div> */}
          </div>
        </div>
      </div>
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={true}
        mediumWidth={false}
        className={
          modalDetail.flag === "cropImage" ? "commonWidth customContent" : ""
        }
        ids={modalDetail.flag === "cropImage" ? "cropImage" : ""}
        child={
          modalDetail.flag === "cropImage" ? (
            <CropImageModal
              close={() => handleOnCloseModal()}
              handleData={(file) => handleChange(file)}
              fileBlob={photoUrl}
              selectedImg={bannerImgUpload}
              setCroppedImage={setBannerImgUpload}
            />
          ) : (
            ""
          )
        }
        header={
          modalDetail.flag === "cropImage" ? (
            <>
              <h2 className="modal_Heading">Crop Image</h2>
              <p onClick={handleOnCloseModal} className="modal_cancel">
                <img src={Image.modalCross} className="ModalCance" alt="" />
              </p>
            </>
          ) : (
            ""
          )
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  );
};

export default ArticleCreation;
