import { CKEditor } from "ckeditor4-react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as images from "../../../utilities/images";
import { getArticleInfo } from "../../../redux/slices/articles";
import { useDispatch } from "react-redux";
import { useArticleSelector } from "../../../redux/selector/article";
import ArticleMessageModal from "../../Modal/articleMessage";
import CustomModal from "../../Modal/CustomModal";
import { useConvertMarkDownHtml } from "../../../redux/customHooks/useConvertMarkDownHtml";
import Slider from "react-slick";

const ArticlesDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const articleSelector = useArticleSelector();
  const { loading } = articleSelector;
  const [articleInfo, setArticleInfo] = useState(state?.articleInfo || "");
  const [articlesTotalAmount, setArticlesTotalAmount] = useState("");
  console.log(articleInfo, "articleInfo");

  const htmlContent = useConvertMarkDownHtml(articleInfo?.description);
  const [key, setKey] = useState(Math.random());
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  /**
   * modal close function
   */

  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  const articleDetails = () => {
    let params = {
      id: id,
    };

    dispatch(
      getArticleInfo({
        ...params,
        cb(res) {
          setArticleInfo(res?.data?.data);
          const sumPublicationAmounts = res?.data?.data?.publications?.reduce(
            (sum, item) => {
              const publicationAmount = item.publicationPrice
                ? parseInt(item.publicationPrice)
                : 0;
              const amount = item.amount || 0;
              return sum + publicationAmount + amount;
            },
            0
          );

          setArticlesTotalAmount(sumPublicationAmounts);
        },
      })
    );
  };

  useEffect(() => {
    articleDetails();
  }, [state?.articleId]);

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={onClick}
      >
        <i
          className="fas fa-arrow-right"
          style={{
            fontSize: "15px",
            color: "#FFF",
            padding: "10px",
            backgroundColor: "#ff5c5d",
            borderRadius: "50%",
            marginRight: "10px 11px",
          }}
        ></i>
      </div>
    );
  }

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={onClick}
      >
        <i
          className="fas fa-arrow-left"
          style={{
            fontSize: "15px",
            color: "#FFF",
            padding: "10px",
            backgroundColor: "#ff5c5d",
            borderRadius: "50%",
            marginLeft: "10px 11px",
          }}
        ></i>
      </div>
    );
  }

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SamplePrevArrow />,
    prevArrow: <SampleNextArrow />,

    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          initialSlide: 2,
        },
      },
    ],
  };

  return (
    <>
      <section className="content-wrapper dashboardSection article_Review ">
        {loading ? (
          <div className="loaderOuter d-flex justify-content-center text-light">
            <div className="spinner-grow text-light" role="status"></div>
          </div>
        ) : (
          <>
            <div className="dashArticle">
              <div className="articleDetail_Fixed">
                <div className="row">
                  <div className="innerHeadleft col-6">
                    <img
                      src={images.arrowLink}
                      className="img-fluid pointCursor"
                      alt="arrowLink image"
                      onClick={() => {
                        navigate(-1);
                      }}
                    />
                    <h2 className="innerHeadText">{articleInfo?.title}</h2>
                  </div>
                  <div className="col-6">
                    <div className="articleFlex justify-content-end">
                      <h4 className="categoryText fw-400">
                        {moment(articleInfo?.createdAt).format("MM/DD/YYYY")}
                      </h4>
                      {!articleInfo?.submittedForPublication &&
                        !articleInfo?.published && (
                          <button
                            type="button"
                            className={
                              articleInfo?.status === "under_review"
                                ? "pendingTkt article_StatusBtn"
                                : articleInfo?.status === "rejected"
                                ? "rejectTkt article_StatusBtn"
                                : "completeTkt article_StatusBtn"
                            }
                          >
                            {articleInfo?.status === "under_review"
                              ? "Under Review"
                              : articleInfo?.status}
                          </button>
                        )}

                      {(articleInfo?.submittedForPublication ||
                        articleInfo?.published) && (
                        <button
                          type="button"
                          className="completeTkt article_StatusBtn"
                        >
                          {articleInfo?.submittedForPublication &&
                          articleInfo?.published
                            ? "published"
                            : "submitted For Publication"}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                {/* <div className="articleSubcategory_ mt-3 ">
                <h4 className="categoryText fw-400">
                  Article Genre:{" "}
                  <strong className="textWhite fw-500">
                    {" "}
                    {articleInfo?.categoryName}
                  </strong>
                </h4>
                <h4 className="categoryText fw-400">
                  Type:{" "}
                  <strong className="textWhite fw-500">
                    {" "}
                    {articleInfo?.subCategoryName}
                  </strong>
                </h4>
              </div> */}

                <div className="d-flex justify-content-between ">
                  <div className="articlePublication_ mt-3 article_Publication_Resp">
                    <h5 className="heading_22">Publication:</h5>
                    <div className="publicationMedia_ publication_Media_Resp">
                      {articleInfo?.publications?.map((val, idx) => (
                        <div className="mediaInner_" key={idx}>
                          <img
                            src={val?.publicationLogo}
                            className="img-fluid me-2"
                            alt="media_image"
                          />
                          <span className="form_subheading">
                            {val?.publicationName}
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div>
                    {articleInfo?.status === "rejected" && (
                      <button
                        className="signUpBtn articleUpdate_Btn"
                        type="button"
                        onClick={() => {
                          const nextState =
                            articleInfo?.status === "rejected"
                              ? "rejected"
                              : "schedule";
                          {
                            articleInfo?.articleType === "ai"
                              ? navigate("/articlesCreation", {
                                  state: {
                                    type: nextState,
                                    draftData: {
                                      ...articleInfo,
                                      publications:
                                        articleInfo.publications?.map(
                                          (publication) => ({
                                            _id: publication.publicationId,
                                            value: publication.publicationName,
                                            publicationLogo:
                                              publication.publicationLogo,
                                            publicationAmount:
                                              publication.publicationPrice,
                                            needInterviewerAssistant:
                                              publication.needInterviewerAssistant,
                                            needProfessionalAssistant:
                                              publication.needProfessionalAssistant,
                                          })
                                        ),
                                    },
                                  },
                                })
                              : navigate("/write-article", {
                                  state: {
                                    type: nextState,
                                    draftData: {
                                      ...articleInfo,
                                      publications:
                                        articleInfo.publications?.map(
                                          (publication) => ({
                                            _id: publication.publicationId,
                                            value: publication.publicationName,
                                            publicationLogo:
                                              publication.publicationLogo,
                                            publicationAmount:
                                              publication.publicationPrice,
                                            needInterviewerAssistant:
                                              publication.needInterviewerAssistant,
                                            needProfessionalAssistant:
                                              publication.needProfessionalAssistant,
                                          })
                                        ),
                                    },
                                  },
                                });
                          }
                        }}
                      >
                        Next
                      </button>
                    )}
                    <button
                      type="button"
                      className="signUpBtn raise_Ticket_Btn mt-3  ms-2"
                      onClick={() => {
                        navigate(`/article-messages/${articleInfo?._id}`, {
                          state: {
                            articleDetails: articleInfo,
                          },
                        });
                        // navigate("/article-messages", {
                        //   state: {
                        //     articleDetails: articleInfo,
                        //   },
                        // });
                      }}
                    >
                      Message
                    </button>
                  </div>
                </div>
                {articleInfo?.url && (
                  <div className="articlePublication_ article_Publication_Resp mt-3">
                    <h5 className="heading_22">Url:</h5>
                    <div className="mediaInner_ d-flex align-items-center">
                      {/* <Link target="_blank" > */}
                      <a
                        href={articleInfo?.url}
                        target="_blank"
                        className="form_subheading"
                      >
                        {articleInfo?.url}
                      </a>
                      {/* </Link> */}
                    </div>
                  </div>
                )}
              </div>

              <div className="articleImage userArticle_Image text-center">
                <img
                  src={articleInfo?.photos}
                  className="img-fluid articlePreviewImage"
                  alt="media_image"
                />
                {articleInfo?.creditImage && (
                  <p className="form_subheading text-center mt-2">
                    {articleInfo?.creditImage}
                  </p>
                )}
              </div>

              <div className="contentPreview_ mt-3 ">
                <div className="ckView row">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: htmlContent,
                    }}
                  />
                </div>
              </div>
              <div style={{ display: "none" }}>
                <CKEditor
                  initData={""}
                  config={{
                    extraPlugins: "easyimage",
                    versionCheck: false,
                  }}
                />
              </div>

              <div className="row mt-4 articleSlider_Row">
                <div className="slider-container articleMultiImg_Slider">
                  {articleInfo?.multipleImages?.length > 1 ? (
                    <Slider {...settings}>
                      {articleInfo?.multipleImages?.map((item, index) => (
                        <div className="p-2">
                          <a
                            key={index}
                            // href={logo?.link}
                            className="marquee_Links"
                            target="_blank"
                          >
                            <div className="client_Logoes">
                              <img
                                src={item}
                                alt="slider_img"
                                className="img-fluid w-100 h-100 "
                              />
                            </div>
                          </a>
                        </div>
                      ))}
                    </Slider>
                  ) : (
                    <>
                      {articleInfo?.multipleImages?.map((item) => (
                        <div className="col-md-4">
                          <div className="mt-3">
                            <img
                              src={item}
                              className="img-fluid articleCover_Img"
                              alt="media_image"
                            />
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                </div>
              </div>

              {articleInfo?.googleDriveUrl && (
                <div className="articlePublication_ article_Publication_Resp mt-3">
                  <h5 className="heading_22">Google Drive Url :-</h5>
                  <div className="mediaInner_ d-flex align-items-center">
                    <a
                      className="form_subheading"
                      href={articleInfo?.googleDriveUrl}
                      target="_blank"
                    >
                      {articleInfo?.googleDriveUrl}
                    </a>
                  </div>
                </div>
              )}

              {articleInfo?.articleType === "ai" && (
                <>
                  {articleInfo?.articleBy !== "company" && (
                    <>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="aiForm_Detail_Parent">
                            <h3 className="aiForm_Detail_Head">
                              Basic Information
                            </h3>
                            <ul>
                              <li className="formDetail_List">
                                Full Name:{" "}
                                <span className="ps-2">
                                  {
                                    articleInfo?.individualsData?.basicInfo
                                      ?.fullName
                                  }
                                </span>
                              </li>
                              <li className="formDetail_List">
                                Professional Title:{" "}
                                <span className="ps-2">
                                  {
                                    articleInfo?.individualsData?.basicInfo
                                      ?.professionalTitle
                                  }
                                </span>
                              </li>
                              <li className="formDetail_List">
                                Industry/Field:{" "}
                                <span className="ps-2">
                                  {
                                    articleInfo?.individualsData?.basicInfo
                                      ?.industry
                                  }
                                </span>
                              </li>
                              <li className="formDetail_List">
                                Website:{" "}
                                <span className="ps-2">
                                  {
                                    articleInfo?.individualsData?.basicInfo
                                      ?.individualWebsite
                                  }
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="col-lg-6 ">
                          <div className="aiForm_Detail_Parent">
                            <h3 className="aiForm_Detail_Head">
                              Personal Background Information
                            </h3>
                            <ul>
                              <li className="formDetail_List">
                                {" "}
                                Brief Overview:
                                <span className="ps-2">
                                  {
                                    articleInfo?.individualsData?.personalInfo
                                      ?.bio
                                  }
                                </span>
                              </li>
                              <li className="formDetail_List">
                                {" "}
                                Key Accomplishments:
                                <span className="ps-2">
                                  {
                                    articleInfo?.individualsData?.personalInfo
                                      ?.accomplishment
                                  }
                                </span>
                              </li>
                              <li className="formDetail_List">
                                Personal Values or Philosophy:{" "}
                                <span className="ps-2">
                                  {
                                    articleInfo?.individualsData?.personalInfo
                                      ?.philosophy
                                  }
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="col-lg-6 ">
                          <div className="aiForm_Detail_Parent">
                            <h3 className="aiForm_Detail_Head">
                              {" "}
                              Professional Experience
                            </h3>
                            <ul>
                              {articleInfo?.individualsData
                                ?.professionalExperiennce?.previousRole && (
                                <li className="formDetail_List">
                                  {" "}
                                  Previous Notable Roles:
                                  <span className="ps-2">
                                    {
                                      articleInfo?.individualsData
                                        ?.professionalExperiennce?.previousRole
                                    }
                                  </span>
                                </li>
                              )}

                              <li className="formDetail_List">
                                Current Role/Position:
                                <span className="ps-2">
                                  {
                                    articleInfo?.individualsData
                                      ?.professionalExperiennce?.currentPosition
                                  }
                                </span>
                              </li>
                              <li className="formDetail_List">
                                {" "}
                                Major Projects/Contributions:
                                <span className="ps-2">
                                  {
                                    articleInfo?.individualsData
                                      ?.professionalExperiennce?.projects
                                  }
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="col-lg-6 ">
                          <div className="aiForm_Detail_Parent">
                            <h3 className="aiForm_Detail_Head">
                              Media or Social Proof
                            </h3>
                            <ul>
                              <li className="formDetail_List">
                                Media Features/Recognition:
                                <span className="ps-2">
                                  {
                                    articleInfo?.individualsData?.media
                                      ?.individualMediaFeature
                                  }
                                </span>
                              </li>
                              <li className="formDetail_List">
                                Social Media Links:{" "}
                                <span className="ps-2">
                                  {
                                    articleInfo?.individualsData?.media
                                      ?.individualSocialMedialinks
                                  }
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                        {articleInfo?.individualsData?.quote && (
                          <div className="col-lg-6 ">
                            <div className="aiForm_Detail_Parent">
                              <h3 className="aiForm_Detail_Head">Quote</h3>
                              <ul>
                                <li className="formDetail_List">
                                  Personal Quote:
                                  <span className="ps-2">
                                    {articleInfo?.individualsData?.quote}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        )}
                      </div>
                    </>
                  )}

                  {articleInfo?.articleBy === "company" && (
                    <>
                      {/*  Company */}
                      <div className="row ps-4 pe-4">
                        <div className="col-lg-6">
                          <div className="aiForm_Detail_Parent">
                            <h3 className="aiForm_Detail_Head">
                              Basic Information
                            </h3>
                            <ul>
                              <li className="formDetail_List">
                                Brand/Company Name:{" "}
                                <span className="ps-2">
                                  {
                                    articleInfo?.companiesData?.basicInfo
                                      ?.companyName
                                  }
                                </span>
                              </li>
                              <li className="formDetail_List">
                                Industry/Field:{" "}
                                <span className="ps-2">
                                  {
                                    articleInfo?.companiesData?.basicInfo
                                      ?.industry
                                  }
                                </span>
                              </li>
                              <li className="formDetail_List">
                                Website:{" "}
                                <span className="ps-2">
                                  {
                                    articleInfo?.companiesData?.basicInfo
                                      ?.companyWebsite
                                  }
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="aiForm_Detail_Parent">
                            <h3 className="aiForm_Detail_Head">
                              Company Overview
                            </h3>
                            <ul>
                              <li className="formDetail_List">
                                Brief Overview:{" "}
                                <span className="ps-2">
                                  {
                                    articleInfo?.companiesData?.companyOverview
                                      ?.briefOverview
                                  }
                                </span>
                              </li>
                              <li className="formDetail_List">
                                Notable Achievements:{" "}
                                <span className="ps-2">
                                  {
                                    articleInfo?.companiesData?.companyOverview
                                      ?.achievements
                                  }
                                </span>
                              </li>
                              {articleInfo?.companiesData?.companyOverview
                                .foundHistory && (
                                <li className="formDetail_List">
                                  Founding Story:{" "}
                                  <span className="ps-2">
                                    {
                                      articleInfo?.companiesData
                                        ?.companyOverview.foundHistory
                                    }
                                  </span>
                                </li>
                              )}
                            </ul>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="aiForm_Detail_Parent">
                            <h3 className="aiForm_Detail_Head">
                              Products/Services
                            </h3>
                            <ul>
                              <li className="formDetail_List">
                                Featured Products/Services:{" "}
                                <span className="ps-2">
                                  {
                                    articleInfo?.companiesData?.products
                                      ?.featuredProduct
                                  }
                                </span>
                              </li>
                              <li className="formDetail_List">
                                NUnique Selling Points:{" "}
                                <span className="ps-2">
                                  {
                                    articleInfo?.companiesData?.products
                                      ?.sellingPoints
                                  }
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="aiForm_Detail_Parent">
                            <h3 className="aiForm_Detail_Head">
                              Executive Leadership Quotes
                            </h3>
                            <ul>
                              {articleInfo?.companiesData?.quotes
                                ?.founderQuote && (
                                <li className="formDetail_List">
                                  CEO/Founder Quotes:{" "}
                                  <span className="ps-2">
                                    {
                                      articleInfo?.companiesData?.quotes
                                        ?.founderQuote
                                    }
                                  </span>
                                </li>
                              )}

                              <li className="formDetail_List">
                                Optional Customer/Partner Quotes:{" "}
                                <span className="ps-2">
                                  {
                                    articleInfo?.companiesData?.quotes
                                      ?.optionalQuote
                                  }
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="aiForm_Detail_Parent">
                            <h3 className="aiForm_Detail_Head">
                              Media or Social Proof
                            </h3>
                            <ul>
                              <li className="formDetail_List">
                                Previous Media Features:{" "}
                                <span className="ps-2">
                                  {
                                    articleInfo?.companiesData?.media
                                      ?.companyMediaFeatures
                                  }
                                </span>
                              </li>
                              <li className="formDetail_List">
                                Notable Partnerships:{" "}
                                <span className="ps-2">
                                  {
                                    articleInfo?.companiesData?.media
                                      ?.partnerhships
                                  }
                                </span>
                              </li>
                              <li className="formDetail_List">
                                Social Media Presence:{" "}
                                <span className="ps-2">
                                  {
                                    articleInfo?.companiesData?.media
                                      ?.companySocialMedialinks
                                  }
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="aiForm_Detail_Parent">
                            <h3 className="aiForm_Detail_Head">
                              Targeted Media Outlet/Industry Focus
                            </h3>
                            <ul>
                              {articleInfo?.companiesData?.focus
                                ?.preferredMedia && (
                                <li className="formDetail_List">
                                  Preferred Media Outlet:{" "}
                                  <span className="ps-2">
                                    {
                                      articleInfo?.companiesData?.focus
                                        ?.preferredMedia
                                    }
                                  </span>
                                </li>
                              )}

                              <li className="formDetail_List">
                                Geographic Focus:
                                <span className="ps-2">
                                  {
                                    articleInfo?.companiesData?.focus
                                      ?.geographic
                                  }
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  {/* Common */}
                  <div className="row ps-4 pe-4">
                    <div className="col-lg-6 ">
                      <div className="aiForm_Detail_Parent">
                        <h3 className="aiForm_Detail_Head">Additional Notes</h3>
                        <ul>
                          <li className="formDetail_List">
                            Special Instructions:
                            <span className="ps-2">
                              {articleInfo?.additionalNote}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-6 ">
                      <div className="aiForm_Detail_Parent">
                        <h3 className="aiForm_Detail_Head"> Key Messages</h3>
                        <ul>
                          <li className="formDetail_List">
                            Main Message/Theme:
                            <span className="ps-2">
                              {articleInfo?.keyMessages?.mainMessage}
                            </span>
                          </li>
                          {articleInfo?.headline && (
                            <li className="formDetail_List">
                              Top 3 Highlights:
                              <span className="ps-2">
                                {articleInfo?.headline}
                              </span>
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-6 ">
                      <div className="aiForm_Detail_Parent">
                        <h3 className="aiForm_Detail_Head">Call to Action</h3>
                        <ul>
                          <li className="formDetail_List">
                            Desired Outcome:
                            <span className="ps-2">
                              {articleInfo?.callToAction?.desiredOutcomes}
                            </span>
                          </li>
                          <li className="formDetail_List">
                            Contact Information:
                            <span className="ps-2">
                              {articleInfo?.callToAction?.contactInfo}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-6 ">
                      <div className="aiForm_Detail_Parent">
                        <h3 className="aiForm_Detail_Head">
                          Tone and Style Preferences
                        </h3>
                        <ul>
                          <li className="formDetail_List">
                            Preferred Tone:
                            <span className="ps-2">
                              {articleInfo?.Preferences?.tone}
                            </span>
                          </li>
                          <li className="formDetail_List">
                            Writing Style:
                            <span className="ps-2">
                              {articleInfo?.Preferences?.style}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-6 ">
                      <div className="aiForm_Detail_Parent">
                        <h3 className="aiForm_Detail_Head">
                          Article Purpose/Goal
                        </h3>
                        <ul>
                          <li className="formDetail_List">
                            Purpose of the Article:
                            <span className="ps-2">
                              {articleInfo?.articlePurpose?.purpose}
                            </span>
                          </li>
                          <li className="formDetail_List">
                            Target Audience:
                            <span className="ps-2">
                              {articleInfo?.articlePurpose?.targetAudience}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-6 ">
                      <div className="aiForm_Detail_Parent">
                        <h3 className="aiForm_Detail_Head">Headline/Title</h3>
                        <ul>
                          <li className="formDetail_List">
                            Suggested Headline:
                            <span className="ps-2">
                              {articleInfo?.keyMessages?.highlights}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-12 ">
                      {articleInfo?.attachments?.logo && (
                        <div className="aiForm_Detail_Parent">
                          <h3 className="aiForm_Detail_Head">Headline/Title</h3>

                          <ul>
                            <li className="formDetail_List mb-0">
                              Upload Image:
                            </li>
                          </ul>
                          <img
                            src={articleInfo?.attachments?.logo}
                            alt="preimage1"
                            className="aiFormUploadImg img-fluid"
                          />
                        </div>
                      )}
                      {articleInfo?.attachments?.files && (
                        <div className="aiForm_Detail_Parent">
                          <ul>
                            <li className="formDetail_List mb-0">
                              Additional Files:
                            </li>
                            <span className="ps-2">
                              <a
                                className="form_subheading pointCursor"
                                href={articleInfo?.attachments?.files}
                                target="_blank"
                                // className="me-3 text-center pointCursor"
                              >
                                {articleInfo?.attachments?.files}
                              </a>
                            </span>
                          </ul>
                          <div className="docfile_Parent">
                            {/* <img
                                  src={images.DOCImage}
                                  alt="preimage1"
                                  className="docfile_Img"
                                /> */}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}

              {articleInfo?.reject?.[0] // Checks if reject array exists and has a truthy first element
                ? (articleInfo?.addOns?.totalAmount || 0) +
                  (articlesTotalAmount || 0) // Case when reject exists
                : (articlesTotalAmount || 0) +
                    (articleInfo?.addOns?.totalAmount || 0) > // Case when reject doesn't exist
                    0 && (
                    <>
                      {" "}
                      <div className="addOnDetailSec">
                        <div className="offerDetailSec mt-md-5 mt-2">
                          <h4 className=" article_Offer">Offer Details</h4>
                          <div className="offerDetailBox mt-2">
                            <div className="offerUpper">
                              <h6 className="ratingText">Item</h6>
                              <h6 className="ratingText">Price</h6>
                            </div>
                            <div className="offerLower">
                              <h6 className="ratingText">
                                {articleInfo?.title}
                              </h6>
                              <h6 className="ratingText">
                                {articleInfo?.reject?.[0]
                                  ? "$" + `${articlesTotalAmount || 0} Paid`
                                  : "$" + articlesTotalAmount || 0}
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="addOnDetailSec mt-md-5 mt-3">
                        <div className="offerDetailSec mt-2">
                          <h4 className="article_Offer">Add-on Details</h4>
                          {articleInfo?.addOns?.professional ||
                          articleInfo?.addOns?.interviewer ||
                          articleInfo?.newAddOns?.interviewer ||
                          articleInfo?.newAddOns?.professional ? (
                            <div className="offerDetailBox mt-2">
                              <div className="offerUpper">
                                <h6 className="ratingText">Item</h6>
                                <h6 className="ratingText">Price</h6>
                              </div>
                              {(articleInfo?.addOns?.professional ||
                                articleInfo?.newAddOns?.professional) && (
                                <div className="offerLower">
                                  <h6 className="ratingText">Professional</h6>
                                  <h6 className="ratingText">
                                    $
                                    {articleInfo?.reject?.[0]
                                      ? articleInfo?.addOns?.professionalAmout
                                        ? `${
                                            articleInfo?.addOns
                                              ?.professionalAmout || 0
                                          } Paid`
                                        : articleInfo?.addOns?.professionalAmout
                                      : articleInfo?.addOns
                                          ?.professionalAmout || 0}
                                  </h6>
                                </div>
                              )}
                              <div className="offerLower addOnArticle_Checkbox mr-3 pt-0 justify-content-start">
                                {articleInfo?.addOns?.professional && (
                                  <>
                                    {articleInfo?.publications?.length > 0
                                      ? articleInfo.publications
                                          .filter(
                                            (item) =>
                                              item?.needProfessionalAssistant
                                          )
                                          .map((item, idx) => (
                                            <div className=" d-flex align-items-center">
                                              <input
                                                type="checkbox"
                                                id={idx}
                                                className="form-check-input addOnArticle_CheckBox mt-0"
                                                checked={
                                                  item?.needProfessionalAssistant
                                                }
                                              />
                                              <h4
                                                className="sub_p_text ms-2"
                                                key={item.id}
                                              >
                                                {item?.publicationName}
                                              </h4>
                                            </div>
                                          ))
                                      : ""}
                                  </>
                                )}
                              </div>

                              {(articleInfo?.addOns?.interviewer ||
                                articleInfo?.newAddOns?.interviewer) && (
                                <div className="offerLower">
                                  <h6 className="ratingText">Interviewer</h6>
                                  <h6 className="ratingText">
                                    {/* ${articleInfo?.reject?.[0] ? `${articleInfo?.addOns?.interviewerAmount ||0} Paid` :articleInfo?.newAddOns?.interviewerAmount } */}
                                    $
                                    {articleInfo?.reject?.[0]
                                      ? articleInfo?.addOns?.interviewerAmount
                                        ? `${
                                            articleInfo?.addOns
                                              ?.interviewerAmount || 0
                                          } Paid`
                                        : articleInfo?.addOns?.interviewerAmount
                                      : articleInfo?.addOns?.interviewerAmount}
                                  </h6>
                                </div>
                              )}

                              <div className="offerLower addOnArticle_Checkbox mr-3 pt-0 justify-content-start">
                                {articleInfo?.addOns?.interviewer && (
                                  <>
                                    {articleInfo?.publications?.length > 0
                                      ? articleInfo.publications
                                          .filter(
                                            (item) =>
                                              item?.needInterviewerAssistant
                                          )
                                          .map((item, idx) => (
                                            <div className=" d-flex align-items-center">
                                              <input
                                                type="checkbox"
                                                id={idx}
                                                className="form-check-input addOnArticle_CheckBox mt-0"
                                                checked={
                                                  item?.needInterviewerAssistant
                                                }
                                              />
                                              <h4
                                                className="sub_p_text ms-2"
                                                key={item.id}
                                              >
                                                {item?.publicationName}
                                              </h4>
                                            </div>
                                          ))
                                      : ""}
                                  </>
                                )}
                              </div>
                            </div>
                          ) : (
                            ""
                          )}

                          <div className="totalBox">
                            <h6 className="ratingText">Add On Total</h6>
                            <h6 className="ratingText">
                              $
                              {articleInfo?.reject?.[0]
                                ? `${
                                    articleInfo?.addOns?.totalAmount || 0
                                  } Paid`
                                : articleInfo?.addOns?.totalAmount || 0}
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div className="totalBox">
                        <h6 className="ratingText">Total Payment</h6>
                        <h6 className="ratingText">
                          $
                          {articleInfo?.reject?.[0]
                            ? `${
                                (articleInfo?.addOns?.totalAmount || 0) +
                                (articlesTotalAmount || 0)
                              } Paid`
                            : (articlesTotalAmount || 0) +
                              (articleInfo?.addOns?.totalAmount || 0)}
                        </h6>
                      </div>
                    </>
                  )}
            </div>
          </>
        )}
      </section>

      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={true}
        mediumWidth={false}
        className={
          modalDetail.flag === "createsubcategory" ||
          modalDetail.flag === "updateCategoryModal" ||
          modalDetail.flag === "messageModal"
            ? "commonWidth customContent"
            : ""
        }
        ids={
          modalDetail.flag === "rejectModal" ||
          modalDetail.flag === "updateCategoryModal"
            ? "subCategoryModal"
            : modalDetail.flag === "messageModal"
            ? "articleMessageModal"
            : ""
        }
        child={
          modalDetail.flag === "messageModal" ? (
            <ArticleMessageModal
              close={() => handleOnCloseModal()}
              articleDetails={articleInfo}
              // handleStatusChange={(e, reject) => handleStatusChange(e, reject)}
              loading={loading}
            />
          ) : (
            ""
          )
        }
        header={
          modalDetail.flag === "messageModal" ? (
            <>
              <h2 className="modal_Heading">Messages</h2>
              <p onClick={handleOnCloseModal} className="modal_cancel">
                <img
                  src={images.modalCross}
                  className="ModalCancel img-fluid"
                  alt=""
                />
              </p>
            </>
          ) : (
            ""
          )
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  );
};

export default ArticlesDetails;
