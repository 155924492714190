import { useStripe } from "@stripe/react-stripe-js";
import { CKEditor } from "ckeditor4-react";
import moment from "moment";
import OpenAI from "openai";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import config from "../../../../config";
import {
  createArticle,
  updateArticleDraft,
} from "../../../../redux/slices/articles";
import * as images from "../../../../utilities/images";
import CustomModal from "../../../Modal/CustomModal";
import ScheduleModal from "../../../Modal/ScheduleModal";
import UnderreviewModal from "../../../Modal/UnderreviewModal";
import PaymentDetail from "../../paymentdetail";
import CardPaymentModal from "../../../Modal/AddCardPaymentModal";
import { getAllPaymentCards } from "../../../../redux/slices/subscription";
import { useConvertMarkDownHtml } from "../../../../redux/customHooks/useConvertMarkDownHtml";
import Slider from "react-slick";
import { useArticleSelector } from "../../../../redux/selector/article";
// import state from "sweetalert/typings/modules/state";

const ArticleDetails = () => {
  const stripe = useStripe();
  const toastId = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const articleSelector = useArticleSelector();
  // const { loading } = articleSelector;
  console.log(location, "locationlocation");

  let selectedPublication =
    location?.state?.selectedPublicationDetails || location?.state;
  selectedPublication = {
    ...selectedPublication,
    ...selectedPublication?.selectedPublication,
  };

  const userInfo = location?.state?.userId || location?.state?.draftData;
  console.log(selectedPublication, "selectedPublication");
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [gptData, setGptData] = useState("");
  const htmlContent = useConvertMarkDownHtml(
    gptData || selectedPublication?.ckEditorContent
  );
  console.log(htmlContent, "htmlContent2222");

  const [gptImg, setGptImg] = useState(selectedPublication?.photo || "");
  const [key, setKey] = useState(Math.random());
  const [articlescheduleInfo, setArticlescheduleInfo] = useState("");
  const [articlesTotalAmount, setArticlesTotalAmount] = useState("");
  console.log(articlesTotalAmount,"articlesTotalAmount");
  
  const [allCardsInfo, setAllCardsInfo] = useState("");
  const [articleLoader, setArticleLoader] = useState("");
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  //closeModal
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  // open modal
  const handleOpenModal = (flag) => {
    const modalFlag = flag?.flags || flag;
    setModalDetail({
      show: true,
      flag: modalFlag,
      type: modalFlag,
    });
    setKey(Math.random());
  };

  // API for get card list (it check for first time ,if no any card save then open direct add card modal otherwise all card list modal).....................
  const getAllCardInfo = () => {
    dispatch(
      getAllPaymentCards({
        cb(res) {
          if (res.status === 200) {
            setAllCardsInfo(res?.data?.data?.data);
          }
        },
      })
    );
  };

  // this is use for pay article payment it come from callback function(CardPaymentModal)
  const paymentToken = (cardInfo) => {
    const { flag, date, time, publicationData } = articlescheduleInfo;
    if (selectedPublication?.type == "submitAIArticle") {
      if (!htmlContent) {
        showToast("No contents found");
        return;
      }
      SubmitAIArticle(flag, date, time, publicationData, cardInfo?.cardId);
    } else {
      SubmitArticle(flag, date, time, publicationData, cardInfo?.cardId);
    }
  };

  // API for create  article ................
  const SubmitArticle = async (flag, date, time, publicationData, cardId) => {
    setArticleLoader(true);
    let params = {
      userId: userInfo?.userId ||selectedPublication?.userInfo
      ?.userId,
      id: userInfo?._id ||selectedPublication?.userInfo
      ?._id ,
      cardId: cardId,
      articleType: "upload",
      creditImage: selectedPublication?.creditImgUpload || undefined,
      url: selectedPublication?.articleUrl,
      publicationId: publicationData,
      categoryName: selectedPublication?.categorydata?.name,
      categoryId: selectedPublication?.categorydata?._id,
      subCategoryName: selectedPublication?.subCategoryData?.name,
      subCategoryId: selectedPublication?.subCategoryData?._id,
      title: selectedPublication?.title || selectedPublication?.data?.title,
      description: selectedPublication?.ckEditorContent || undefined,
      googleDriveUrl: selectedPublication?.googleDriveUrl || undefined,
      multipleImages: selectedPublication?.multipleImages || undefined,
      photos: selectedPublication?.photo,
      needInterviewerAssistant: selectedPublication?.publicationIds?.map(
        (data) => false
      ),
      needProfessionalAssistant: selectedPublication?.publicationIds?.map(
        (data) =>  false),
      saveAsDraft: flag,
      date: date || undefined,
      addOn: "0",
    };

    console.log(params,"paramsparams");
    

    if (time) {
      params = {
        ...params,
        time: moment(time).format("HH:mm"),
      };
    }
    dispatch(
      updateArticleDraft({
        ...params,
        async cb(res) {
          setArticleLoader(false);
          if (res?.data?.data?.status === "under_review") {
            handleOpenModal("underreviewModal");
          } else if (res?.data?.data?.status === "schedule") {
            navigate("/drafts", { state: { flag: "schedule" } });
          } else if (res?.data?.data?.status === "draft") {
            navigate("/drafts");
          }

          // if (
          //   res?.data?.data?.clientSecret?.client_secret &&
          //   res?.data?.data?.clientSecret?.status == "requires_confirmation"
          // ) {
          //   try {
          //     const paymentResult = await stripe.confirmCardPayment(
          //       res?.data?.data?.clientSecret?.client_secret
          //     );
          //     if (paymentResult.error) {
          //       toast.error("Payment confirmation error");
          //       return;
          //     } else {
          //       toast.success("Article payment successfully");
          //     }
          //   } catch (error) {
          //     console.error("Payment error:", error);
          //   }
          // }
        },
      })
    );
  };

  // API for create AI article ................
  const SubmitAIArticle = (flag, date, time, publicationData, cardId) => {
    let aiParams = {
      cardId: cardId,
      articleType: "ai",
      creditImage: selectedPublication?.creditImgUpload || undefined,
      url: selectedPublication?.data?.articleUrl,
      articleBy: selectedPublication?.options,
      publicationId: publicationData,
      categoryName:
        selectedPublication?.categorydata?.name ||
        selectedPublication?.draftData?.categoryName,
      categoryId:
        selectedPublication?.categorydata?._id ||
        selectedPublication?.draftData?.categoryId,
      subCategoryName: selectedPublication?.subCategoryData?.name,
      subCategoryId: selectedPublication?.subCategoryData?._id,
      photos: [selectedPublication?.photo],
      description: htmlContent,
      saveAsDraft: flag,
      title: selectedPublication?.title || selectedPublication?.data?.title,
      date: date || undefined,
      addOn: "0",
      purpose: selectedPublication?.data?.purpose,
      targetAudience: selectedPublication?.data?.targetAudience,
      headline: selectedPublication?.data?.headline || undefined, // optional
      mainMessage: selectedPublication?.data?.mainMessage,
      highlights: selectedPublication?.data?.highlights,
      desiredOutcomes: selectedPublication?.data?.desiredOutcomes,
      contactInfo: selectedPublication?.data?.contactInfo,
      tone: selectedPublication?.data?.tone,
      style: selectedPublication?.data?.style,
      additionalNote: selectedPublication?.data?.additionalNote,
      logo: selectedPublication?.data?.logo || undefined, // optional
      files: selectedPublication?.data?.files || undefined, // optional
      needInterviewerAssistant: selectedPublication?.publicationIds?.map(
        (data) => false
      ),
      needProfessionalAssistant: selectedPublication?.publicationIds?.map(
        (data) =>  false),
    };
    if (selectedPublication?.options === "company") {
      aiParams = {
        ...aiParams,
        industry: selectedPublication?.data?.industry,
        companyName: selectedPublication?.data?.companyName,
        companyWebsite: selectedPublication?.data?.companyWebsite,
        briefOverview: selectedPublication?.data?.briefOverview,
        foundHistory: selectedPublication?.data?.foundHistory || undefined, // optional
        achievements: selectedPublication?.data?.achievements,
        featuredProduct: selectedPublication?.data?.featuredProduct,
        sellingPoints: selectedPublication?.data?.sellingPoints,
        founderQuote: selectedPublication?.data?.founderQuote || undefined, // optional
        optionalQuote: selectedPublication?.data?.optionalQuote,
        companyMediaFeatures: selectedPublication?.data?.companyMediaFeatures,
        partnerhships: selectedPublication?.data?.partnerhships,
        companySocialMedialinks:
          selectedPublication?.data?.companySocialMedialinks,
        preferredMedia: selectedPublication?.data?.preferredMedia || undefined, // optional
        geographic: selectedPublication?.data?.geographic,
      };
    } else {
      aiParams = {
        ...aiParams,
        fullName: selectedPublication?.data?.fullName,
        professionalTitle: selectedPublication?.data?.professionalTitle,
        industry: selectedPublication?.data?.industry,
        individualWebsite: selectedPublication?.data?.individualWebsite,
        bio: selectedPublication?.data?.bio,
        accomplishment: selectedPublication?.data?.accomplishment,
        philosophy: selectedPublication?.data?.philosophy,
        currentPosition: selectedPublication?.data?.currentPosition,
        previousRole: selectedPublication?.data?.previousRole || undefined, // optional
        projects: selectedPublication?.data?.projects,
        quote: selectedPublication?.data?.quote || undefined, // optional
        individualMediaFeature:
          selectedPublication?.data?.individualMediaFeature,
        individualSocialMedialinks: [
          selectedPublication?.data?.individualSocialMedialinks,
        ],
      };
    }
    if (time) {
      aiParams = {
        ...aiParams,
        time: moment(time).format("HH:mm"),
      };
    }
    dispatch(
      createArticle({
        ...aiParams,
        async cb(res) {
          if (res) {
            if (res?.data?.data?.status === "under_review") {
              handleOpenModal("underreviewModal");
            } else if (res?.data?.data?.status === "schedule") {
              navigate("/drafts", { state: { flag: "schedule" } });
            } else if (res?.data?.data?.status === "draft") {
              navigate("/drafts");
            }
            // if (
            //   res?.data?.data?.clientSecret?.client_secret &&
            //   res?.data?.data?.clientSecret?.status == "requires_confirmation"
            // ) {
            //   try {
            //     const paymentResult = await stripe.confirmCardPayment(
            //       res?.data?.data?.clientSecret?.client_secret
            //     );
            //     if (paymentResult.error) {
            //       toast.error("Payment confirmation error");
            //       return;
            //     } else {
            //       toast.success("Article payment successfully");
            //     }
            //   } catch (error) {
            //     console.error("Payment error:", error);
            //   }
            // }
          }
        },
      })
    );
  };

  // show only one toast at one time
  const showToast = (msg) => {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error(msg);
    }
  };

  // FUNCTION FOR USE HANDLE CREATE ARTICLE WITH PAYMENT AND NON PAYMENT//////////////////////////////////////////////
  const createNewArticle = (flag, time, date) => {
    const publicationData = selectedPublication?.publicationIds?.map(
      (data) => data._id
    );

    const sumPublicationAmounts = selectedPublication?.publicationIds?.reduce(
      (sum, item) => {
        const publicationAmount = item.publicationAmount
          ? parseInt(item.publicationAmount)
          : 0;
        const amount = item.amount || 0;
        return sum + publicationAmount + amount;
      },
      0
    );

    setArticlesTotalAmount(sumPublicationAmounts);

    if (selectedPublication?.type == "submitAIArticle") {
      if (!htmlContent) {
        showToast("No contents found");
        return;
      }
      if (sumPublicationAmounts > 0 && flag != "1" && (!userInfo?.reject ||userInfo?.reject?.length == 0)) {
        if (allCardsInfo?.length == 0) {
          handleOpenModal("paymentModal");
          setArticlescheduleInfo({ flag, date, time, publicationData });
        } else {
          handleOpenModal("cardPaymentModal");
          setArticlescheduleInfo({ flag, date, time, publicationData });
        }
      } else {
        SubmitAIArticle(flag, date, time, publicationData);
      }
    } else {
      if (
        (!userInfo?.reject ||userInfo?.reject?.length == 0) &&
        (userInfo?.totalAmount > 0 || sumPublicationAmounts > 0) &&
        flag != "1"
      ) {
        if (allCardsInfo?.length == 0) {
          handleOpenModal("paymentModal");
          setArticlescheduleInfo({ flag, date, time, publicationData });
        } else {
          handleOpenModal("cardPaymentModal");
          setArticlescheduleInfo({ flag, date, time, publicationData });
        }
      } else {
        SubmitArticle(flag, date, time, publicationData);
      }
    }
  };

  useEffect(() => {
    //   /***************************************To Generate Article via Chat Gpt*******************************/
    if (
      !selectedPublication?.draftData &&
      (selectedPublication?.type === "articleAI" ||
        selectedPublication?.type === "submitAIArticle")
    ) {
      const openai = new OpenAI({
        apiKey: config.OPEN_AI_DEVELOPER_KEY,
        dangerouslyAllowBrowser: true,
      });

      async function main() {
        let content = [
          {
            role: "user",
            content:
              "write a 1000 words article for above mentioned content in large explanation",
          },
        ];
        if (selectedPublication?.categorydata?.name)
          content.push({
            role: "user",
            content: selectedPublication?.categorydata?.name,
          });
        if (selectedPublication?.subCategoryData?.name)
          content.push({
            role: "user",
            content: selectedPublication?.subCategoryData?.name,
          });
        if (selectedPublication?.data?.entityName)
          content.push({
            role: "user",
            content: selectedPublication?.data?.entityName,
          });
        if (selectedPublication?.data?.callAction)
          content.push({
            role: "user",
            content: selectedPublication?.data?.callAction,
          });
        if (selectedPublication?.data?.news)
          content.push({
            role: "user",
            content: selectedPublication?.data?.news,
          });
        if (selectedPublication?.data?.relevantKeyWords)
          content.push({
            role: "user",
            content: selectedPublication?.data?.relevantKeyWords,
          });
        if (selectedPublication?.data?.links)
          content.push({
            role: "user",
            content: `add ${selectedPublication?.data?.links} link as reference in article.`,
          });
        if (
          !selectedPublication?.categorydata?.name &&
          !selectedPublication?.subCategoryData?.name &&
          !selectedPublication?.data?.entityName &&
          !selectedPublication?.data?.callAction &&
          !selectedPublication?.data?.news &&
          !selectedPublication?.data?.relevantKeyWords
        ) {
          // toast.error("Please enter data to get result");
          // return;
        }

        try {
          setLoading(true);

          // const image = await openai.images.generate({
          //   model: "dall-e-2",
          //   prompt: `Image for ${selectedPublication?.categorydata?.name} having ${selectedPublication?.subCategoryData?.name}`,
          // });

          // setGptImg(image);

          const chatCompletion = await openai.chat.completions.create({
            messages: content,
            model: "gpt-4o",
            // stream: true,
          });

          setGptData(chatCompletion.choices[0].message.content);

          setLoading(false);
          return chatCompletion;
        } catch (error) {
          setLoading(false);
          if (
            error.response &&
            error.response.data &&
            error.response.data.error
          ) {
            showToast(`API Error: ${error.response.data.error.message}`);
          } else {
            showToast("An error occurred while processing your request.");
          }
        }
      }
      main();
    }
  }, []);

  useEffect(() => {
    getAllCardInfo();
  }, []);

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={onClick}
      >
        <i
          className="fas fa-arrow-right"
          style={{
            fontSize: "15px",
            color: "#FFF",
            padding: "10px",
            backgroundColor: "#ff5c5d",
            borderRadius: "50%",
            marginRight: "10px 11px",
          }}
        ></i>
      </div>
    );
  }

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={onClick}
      >
        <i
          className="fas fa-arrow-left"
          style={{
            fontSize: "15px",
            color: "#FFF",
            padding: "10px",
            backgroundColor: "#ff5c5d",
            borderRadius: "50%",
            marginLeft: "10px 11px",
          }}
        ></i>
      </div>
    );
  }

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SamplePrevArrow />,
    prevArrow: <SampleNextArrow />,

    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          initialSlide: 2,
        },
      },
    ],
  };

  return (
    <>
      <section className="content-wrapper dashboardSection pt-0">
        <div className="dashArticle">
          <div className="articleDetail_Fixed">
            <div className="innerHead justify-content-between">
              <div className="innerHeadleft">
                <img
                  src={images.arrowLink}
                  className="img-fluid pointCursor"
                  alt="arrowLink image"
                  onClick={() => {
                    if (
                      location?.state?.selectedPublicationDetails?.type ===
                      "articleAI"
                    ) {
                      navigate("/articlesCreation", {
                        state: location?.state?.selectedPublicationDetails,
                      });
                    } else {
                      if (location?.state?.type !== "submitArticle") {
                        navigate("/write-article", {
                          state: {
                            ...location?.state?.selectedPublicationDetails,
                            flag: "SelectedPublication",
                          },
                        });
                      } else {
                        navigate("/addOn-Article", {
                          state: {
                            type: "CreateArticle",
                            userInfo,
                            selectedPublication,
                            articlesTotalAmount,
                            htmlContent,
                          },
                        });
                      }
                    }
                  }}
                />
                <h2 className="innerHeadText">
                  {selectedPublication?.title ||
                    selectedPublication?.data?.title}
                </h2>
              </div>
              <h4 className="categoryText fw-400">
                {moment().format("MM/DD/YYYY")}
              </h4>
            </div>
            {(selectedPublication?.type == "articleAI" ||
              selectedPublication?.type == "submitAIArticle") && (
              <div className="articleSubcategory_ mt-3 articles_SubCategory">
                <h4 className="categoryText fw-400 ">
                  Article Genre:
                  <strong className="textWhite fw-500 ms-2">
                    {selectedPublication?.categorydata?.name ||
                      selectedPublication?.draftData?.categoryName}
                  </strong>
                </h4>
                {/* <h4 className="categoryText fw-400 ">
            Type:
            <strong className="textWhite fw-500 ms-2">
              {selectedPublication?.subCategoryData?.name}
            </strong>
          </h4> */}
              </div>
            )}

            <div className="articlePublication_ article_Publication_Resp mt-3">
              <h5 className="heading_22">Publication:</h5>
              {selectedPublication?.publicationIds?.length > 0 ? (
                selectedPublication?.publicationIds?.map((val, index) => (
                  <div
                    className="publicationMedia_ publication_Media_Resp"
                    key={index}
                  >
                    <div className="mediaInner_ d-flex align-items-center">
                      <img
                        src={
                          val?.publicationLogo
                            ? val?.publicationLogo
                            : images.media1
                        }
                        className="img-fluid me-2"
                        alt="media_image"
                      />
                      <span className="form_subheading">{val?.value}</span>
                    </div>
                  </div>
                ))
              ) : (
                <td colSpan={8} className="noFoundHead">
                  <p className="text-center noFoundText">
                    No publication Found!
                  </p>
                </td>
              )}
            </div>
            {(selectedPublication?.articleUrl ||
              selectedPublication?.data?.articleUrl) && (
              <div className="articlePublication_ article_Publication_Resp mt-3">
                <h5 className="heading_22">Url:</h5>
                <div className="mediaInner_ d-flex align-items-center">
                  <a
                    className="form_subheading"
                    href={
                      selectedPublication?.articleUrl ||
                      selectedPublication?.data?.articleUrl
                    }
                    target="_blank"
                  >
                    {selectedPublication?.articleUrl ||
                      selectedPublication?.data?.articleUrl}
                  </a>
                </div>
              </div>
            )}
          </div>

          {selectedPublication?.type === "articleAI" ||
          selectedPublication?.type === "submitAIArticle" ? (
            <>
              {loading ? (
                <div className="loaderOuter d-flex justify-content-center text-light flex-column align-items-center">
                  <div className="spinner-grow text-light" role="status"></div>
                  <p> Please wait, we are processing your request.</p>
                </div>
              ) : (
                <>
                  <div className="articleImage mt-4 text-center ">
                    {gptImg && (
                      <img
                        src={gptImg}
                        className="img-fluid me-2 articleCover_Img"
                        alt="media_image"
                      />
                    )}
                  </div>

                  {selectedPublication?.creditImgUpload && (
                    <>
                      <p className="form_subheading text-center mt-2">
                        {selectedPublication?.creditImgUpload}
                      </p>
                    </>
                  )}
                  <div className="contentPreview_ mt-3">
                    <div className="row">
                      <div className="form-group abhishek articleeditor_ mt-3 position-relative">
                        {!!htmlContent && (
                          <CKEditor
                            initData={htmlContent}
                            config={{
                              extraPlugins: ["easyimage"],
                              versionCheck: false,
                              removePlugins: [
                                "ImageUpload",
                                "MediaEmbed",
                                "Table",
                                "TableToolbar",
                              ],
                              versionCheck: false,
                              toolbarLocation: "top",
                              removeButtons:
                                "Table,TableToolbar,Anchor,HorizontalRule,Blockquote",
                              toolbar: [
                                { name: "document", items: ["Undo", "Redo"] },
                                { name: "styles", items: ["Format"] },
                                {
                                  name: "basicstyles",
                                  items: [
                                    "Bold",
                                    "Italic",
                                    "Strike",
                                    "-",
                                    "RemoveFormat",
                                  ],
                                },
                                {
                                  name: "paragraph",
                                  items: ["NumberedList", "BulletedList"],
                                },
                                { name: "links", items: ["Link", "Unlink"] },
                                { name: "insert", items: ["EasyImageUpload"] },
                              ],
                              cloudServices_uploadUrl:
                                "https://33333.cke-cs.com/easyimage/upload/",
                              cloudServices_tokenUrl:
                                "https://33333.cke-cs.com/token/dev/ijrDsqFix838Gh3wGO3F77FSW94BwcLXprJ4APSp3XQ26xsUHTi0jcb1hoBt",
                              easyimage_styles: {
                                gradient1: {
                                  group: "easyimage-gradients",
                                  attributes: {
                                    className: "easyimage-gradient-1",
                                  },
                                  label: "Blue Gradient",
                                  icon: "https://ckeditor.com/docs/ckeditor4/4.24.0-lts/examples/assets/easyimage/icons/gradient1.png",
                                  iconHiDpi:
                                    "https://ckeditor.com/docs/ckeditor4/4.24.0-lts/examples/assets/easyimage/icons/hidpi/gradient1.png",
                                },
                                gradient2: {
                                  group: "easyimage-gradients",
                                  attributes: {
                                    className: "easyimage-gradient-2",
                                  },
                                  label: "Pink Gradient",
                                  icon: "https://ckeditor.com/docs/ckeditor4/4.24.0-lts/examples/assets/easyimage/icons/gradient2.png",
                                  iconHiDpi:
                                    "https://ckeditor.com/docs/ckeditor4/4.24.0-lts/examples/assets/easyimage/icons/hidpi/gradient2.png",
                                },
                                noGradient: {
                                  group: "easyimage-gradients",
                                  attributes: {
                                    className: "easyimage-no-gradient",
                                  },
                                  label: "No Gradient",
                                  icon: "https://ckeditor.com/docs/ckeditor4/4.24.0-lts/examples/assets/easyimage/icons/nogradient.png",
                                  iconHiDpi:
                                    "https://ckeditor.com/docs/ckeditor4/4.24.0-lts/examples/assets/easyimage/icons/hidpi/nogradient.png",
                                },
                              },
                              easyimage_toolbar: [
                                "EasyImageFull",
                                // "EasyImageSide",
                                "EasyImageGradient1",
                                "EasyImageGradient2",
                                "EasyImageNoGradient",
                                "EasyImageAlt",
                              ],
                              height: 630,
                              removeButtons: "PasteFromWord",
                              contentsCss: [
                                "ckEditor.css",
                                ".ckeditor-content-colour { color: #ffffff !important; font-family: sans-serif; font-size: 16px ; font-weight: 400  }",
                              ],
                              bodyClass: "ckeditor-content-colour",
                            }}
                            name="editor"
                            readOnly={false}
                            onChange={(event) => {
                              const editor = event.editor;
                              const data = editor.getData();
                              setGptData(data);
                            }}
                            type="classic"
                          />
                        )}
                      </div>
                    </div>

                    {(selectedPublication?.type === "submitAIArticle" ||
                      selectedPublication.type === "articleAI") && (
                      <>
                        {/* Individual */}
                        {selectedPublication?.options !== "company" && (
                          <>
                            <div className="row">
                              <div className="col-lg-6">
                                <div className="aiForm_Detail_Parent">
                                  <h3 className="aiForm_Detail_Head">
                                    Basic Information
                                  </h3>
                                  <ul>
                                    <li className="formDetail_List">
                                      Full Name:{" "}
                                      <span className="ps-2">
                                        {selectedPublication?.data?.fullName}
                                      </span>
                                    </li>
                                    <li className="formDetail_List">
                                      Professional Title:{" "}
                                      <span className="ps-2">
                                        {
                                          selectedPublication?.data
                                            ?.professionalTitle
                                        }
                                      </span>
                                    </li>
                                    <li className="formDetail_List">
                                      Industry/Field:{" "}
                                      <span className="ps-2">
                                        {selectedPublication?.data?.industry}
                                      </span>
                                    </li>
                                    <li className="formDetail_List">
                                      Website:{" "}
                                      <span className="ps-2">
                                        {
                                          selectedPublication?.data
                                            ?.individualWebsite
                                        }
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div className="col-lg-6 ">
                                <div className="aiForm_Detail_Parent">
                                  <h3 className="aiForm_Detail_Head">
                                    Personal Background Information
                                  </h3>
                                  <ul>
                                    <li className="formDetail_List">
                                      {" "}
                                      Brief Overview:
                                      <span className="ps-2">
                                        {selectedPublication?.data?.bio}
                                      </span>
                                    </li>
                                    <li className="formDetail_List">
                                      {" "}
                                      Key Accomplishments:
                                      <span className="ps-2">
                                        {
                                          selectedPublication?.data
                                            ?.accomplishment
                                        }
                                      </span>
                                    </li>
                                    <li className="formDetail_List">
                                      Personal Values or Philosophy:{" "}
                                      <span className="ps-2">
                                        {selectedPublication?.data?.philosophy}
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div className="col-lg-6 ">
                                <div className="aiForm_Detail_Parent">
                                  <h3 className="aiForm_Detail_Head">
                                    {" "}
                                    Professional Experience
                                  </h3>
                                  <ul>
                                    {selectedPublication?.data
                                      ?.previousRole && (
                                      <li className="formDetail_List">
                                        {" "}
                                        Previous Notable Roles:
                                        <span className="ps-2">
                                          {
                                            selectedPublication?.data
                                              ?.previousRole
                                          }
                                        </span>
                                      </li>
                                    )}

                                    <li className="formDetail_List">
                                      Current Role/Position:
                                      <span className="ps-2">
                                        {
                                          selectedPublication?.data
                                            ?.currentPosition
                                        }
                                      </span>
                                    </li>
                                    <li className="formDetail_List">
                                      {" "}
                                      Major Projects/Contributions:
                                      <span className="ps-2">
                                        {selectedPublication?.data?.projects}
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div className="col-lg-6 ">
                                <div className="aiForm_Detail_Parent">
                                  <h3 className="aiForm_Detail_Head">
                                    Media or Social Proof
                                  </h3>
                                  <ul>
                                    <li className="formDetail_List">
                                      Media Features/Recognition:
                                      <span className="ps-2">
                                        {
                                          selectedPublication?.data
                                            ?.individualMediaFeature
                                        }
                                      </span>
                                    </li>
                                    <li className="formDetail_List">
                                      Social Media Links:{" "}
                                      <span className="ps-2">
                                        {
                                          selectedPublication?.data
                                            ?.individualSocialMedialinks
                                        }
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              {selectedPublication?.data?.quote && (
                                <div className="col-lg-6 ">
                                  <div className="aiForm_Detail_Parent">
                                    <h3 className="aiForm_Detail_Head">
                                      Quote
                                    </h3>
                                    <ul>
                                      <li className="formDetail_List">
                                        Personal Quote:
                                        <span className="ps-2">
                                          {selectedPublication?.data?.quote}
                                        </span>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              )}
                            </div>
                          </>
                        )}

                        {selectedPublication?.options === "company" && (
                          <>
                            {/*  Company */}
                            <div className="row">
                              <div className="col-lg-6">
                                <div className="aiForm_Detail_Parent">
                                  <h3 className="aiForm_Detail_Head">
                                    Basic Information
                                  </h3>
                                  <ul>
                                    <li className="formDetail_List">
                                      Brand/Company Name:{" "}
                                      <span className="ps-2">
                                        {selectedPublication?.data?.companyName}
                                      </span>
                                    </li>
                                    <li className="formDetail_List">
                                      Industry/Field:{" "}
                                      <span className="ps-2">
                                        {selectedPublication?.data?.industry}
                                      </span>
                                    </li>
                                    <li className="formDetail_List">
                                      Website:{" "}
                                      <span className="ps-2">
                                        {
                                          selectedPublication?.data
                                            ?.companyWebsite
                                        }
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="aiForm_Detail_Parent">
                                  <h3 className="aiForm_Detail_Head">
                                    Company Overview
                                  </h3>
                                  <ul>
                                    <li className="formDetail_List">
                                      Brief Overview:{" "}
                                      <span className="ps-2">
                                        {
                                          selectedPublication?.data
                                            ?.briefOverview
                                        }
                                      </span>
                                    </li>
                                    <li className="formDetail_List">
                                      Notable Achievements:{" "}
                                      <span className="ps-2">
                                        {
                                          selectedPublication?.data
                                            ?.achievements
                                        }
                                      </span>
                                    </li>
                                    {selectedPublication?.data
                                      ?.foundHistory && (
                                      <li className="formDetail_List">
                                        Founding Story:{" "}
                                        <span className="ps-2">
                                          {
                                            selectedPublication?.data
                                              ?.foundHistory
                                          }
                                        </span>
                                      </li>
                                    )}
                                  </ul>
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="aiForm_Detail_Parent">
                                  <h3 className="aiForm_Detail_Head">
                                    Products/Services
                                  </h3>
                                  <ul>
                                    <li className="formDetail_List">
                                      Featured Products/Services:{" "}
                                      <span className="ps-2">
                                        {
                                          selectedPublication?.data
                                            ?.featuredProduct
                                        }
                                      </span>
                                    </li>
                                    <li className="formDetail_List">
                                      NUnique Selling Points:{" "}
                                      <span className="ps-2">
                                        {
                                          selectedPublication?.data
                                            ?.sellingPoints
                                        }
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="aiForm_Detail_Parent">
                                  <h3 className="aiForm_Detail_Head">
                                    Executive Leadership Quotes
                                  </h3>
                                  <ul>
                                    {selectedPublication?.data
                                      ?.founderQuote && (
                                      <li className="formDetail_List">
                                        CEO/Founder Quotes:{" "}
                                        <span className="ps-2">
                                          {
                                            selectedPublication?.data
                                              ?.founderQuote
                                          }
                                        </span>
                                      </li>
                                    )}

                                    <li className="formDetail_List">
                                      Optional Customer/Partner Quotes:{" "}
                                      <span className="ps-2">
                                        {
                                          selectedPublication?.data
                                            ?.optionalQuote
                                        }
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="aiForm_Detail_Parent">
                                  <h3 className="aiForm_Detail_Head">
                                    Media or Social Proof
                                  </h3>
                                  <ul>
                                    <li className="formDetail_List">
                                      Previous Media Features:{" "}
                                      <span className="ps-2">
                                        {
                                          selectedPublication?.data
                                            ?.companyMediaFeatures
                                        }
                                      </span>
                                    </li>
                                    <li className="formDetail_List">
                                      Notable Partnerships:{" "}
                                      <span className="ps-2">
                                        {
                                          selectedPublication?.data
                                            ?.partnerhships
                                        }
                                      </span>
                                    </li>
                                    <li className="formDetail_List">
                                      Social Media Presence:{" "}
                                      <span className="ps-2">
                                        {
                                          selectedPublication?.data
                                            ?.companySocialMedialinks
                                        }
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="aiForm_Detail_Parent">
                                  <h3 className="aiForm_Detail_Head">
                                    Targeted Media Outlet/Industry Focus
                                  </h3>
                                  <ul>
                                    {selectedPublication?.data
                                      ?.preferredMedia && (
                                      <li className="formDetail_List">
                                        Preferred Media Outlet:{" "}
                                        <span className="ps-2">
                                          {
                                            selectedPublication?.data
                                              ?.preferredMedia
                                          }
                                        </span>
                                      </li>
                                    )}

                                    <li className="formDetail_List">
                                      Geographic Focus:
                                      <span className="ps-2">
                                        {selectedPublication?.data?.geographic}
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </>
                        )}

                        {/* Common */}
                        <div className="row">
                          <div className="col-lg-6 ">
                            <div className="aiForm_Detail_Parent">
                              <h3 className="aiForm_Detail_Head">
                                Additional Notes
                              </h3>
                              <ul>
                                <li className="formDetail_List">
                                  Special Instructions:
                                  <span className="ps-2">
                                    {selectedPublication?.data?.additionalNote}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-lg-6 ">
                            <div className="aiForm_Detail_Parent">
                              <h3 className="aiForm_Detail_Head">
                                {" "}
                                Key Messages
                              </h3>
                              <ul>
                                <li className="formDetail_List">
                                  Main Message/Theme:
                                  <span className="ps-2">
                                    {selectedPublication?.data?.mainMessage}
                                  </span>
                                </li>
                                {selectedPublication?.data?.headline && (
                                  <li className="formDetail_List">
                                    Top 3 Highlights:
                                    <span className="ps-2">
                                      {selectedPublication?.data?.headline}
                                    </span>
                                  </li>
                                )}
                              </ul>
                            </div>
                          </div>
                          <div className="col-lg-6 ">
                            <div className="aiForm_Detail_Parent">
                              <h3 className="aiForm_Detail_Head">
                                Call to Action
                              </h3>
                              <ul>
                                <li className="formDetail_List">
                                  Desired Outcome:
                                  <span className="ps-2">
                                    {selectedPublication?.data?.desiredOutcomes}
                                  </span>
                                </li>
                                <li className="formDetail_List">
                                  Contact Information:
                                  <span className="ps-2">
                                    {selectedPublication?.data?.contactInfo}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-lg-6 ">
                            <div className="aiForm_Detail_Parent">
                              <h3 className="aiForm_Detail_Head">
                                Tone and Style Preferences
                              </h3>
                              <ul>
                                <li className="formDetail_List">
                                  Preferred Tone:
                                  <span className="ps-2">
                                    {selectedPublication?.data?.tone}
                                  </span>
                                </li>
                                <li className="formDetail_List">
                                  Writing Style:
                                  <span className="ps-2">
                                    {selectedPublication?.data?.style}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-lg-6 ">
                            <div className="aiForm_Detail_Parent">
                              <h3 className="aiForm_Detail_Head">
                                Article Purpose/Goal
                              </h3>
                              <ul>
                                <li className="formDetail_List">
                                  Purpose of the Article:
                                  <span className="ps-2">
                                    {selectedPublication?.data?.purpose}
                                  </span>
                                </li>
                                <li className="formDetail_List">
                                  Target Audience:
                                  <span className="ps-2">
                                    {selectedPublication?.data?.targetAudience}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-lg-6 ">
                            <div className="aiForm_Detail_Parent">
                              <h3 className="aiForm_Detail_Head">
                                Headline/Title
                              </h3>
                              <ul>
                                <li className="formDetail_List">
                                  Suggested Headline:
                                  <span className="ps-2">
                                    {selectedPublication?.data?.highlights}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-lg-12 ">
                            {selectedPublication?.data?.logo && (
                              <div className="aiForm_Detail_Parent">
                                <h3 className="aiForm_Detail_Head">
                                  Headline/Title
                                </h3>

                                <ul>
                                  <li className="formDetail_List mb-0">
                                    Upload Image:
                                  </li>
                                </ul>
                                <img
                                  src={selectedPublication?.data?.logo}
                                  alt="preimage1"
                                  className="aiFormUploadImg img-fluid"
                                />
                              </div>
                            )}
                            {selectedPublication?.data?.files && (
                              <div className="aiForm_Detail_Parent">
                                <ul>
                                  <li className="formDetail_List mb-0">
                                    Additional Files:
                                  </li>
                                  <span className="ps-2">
                                    <a
                                      className="form_subheading pointCursor"
                                      href={selectedPublication?.data?.files}
                                      target="_blank"
                                      // className="me-3 text-center pointCursor"
                                    >
                                      {selectedPublication?.data?.files}
                                    </a>
                                  </span>
                                </ul>
                                <div className="docfile_Parent">
                                  {/* <img
                                  src={images.DOCImage}
                                  alt="preimage1"
                                  className="docfile_Img"
                                /> */}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </>
                    )}

                    {selectedPublication.type === "articleAI" ? (
                      <>
                        {/* <button
                          className="signUpBtn  mt-5 me-3"
                          type="button"
                          onClick={() => {
                            navigate("/article-details", {
                              state: {
                                ...selectedPublication,
                                type: "submitAIArticle",
                              },
                            });
                          }}
                        >
                          Skip
                        </button> */}
                        <button
                          className="signUpBtn  me-3 mt-3"
                          type="button"
                          onClick={() => {
                            navigate("/articlesCreation", {
                              state:
                                location?.state?.selectedPublicationDetails,
                              // type: "submitAIArticle",
                            });
                          }}
                        >
                          Back
                        </button>

                        <button
                          className="signUpBtn me-3 mt-3"
                          type="button"
                          onClick={() => {
                            navigate("/addOn-Article", {
                              state: {
                                type: location?.state
                                  ?.selectedPublicationDetails?.type,
                                userInfo,
                                selectedPublication,
                                articlesTotalAmount,
                                htmlContent,
                              },
                            });
                          }}
                        >
                          Next
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          className="signUpBtn  me-3 mt-5"
                          type="button"
                          onClick={() => {
                            navigate("/addOn-Article", {
                              state: {
                                selectedPublication: {
                                  ...location?.state?.selectedPublication,
                                  type:
                                    location?.state?.type == "submitAIArticle"
                                      ? "articleAI"
                                      : "articleAI",
                                },
                                type:
                                  location?.state?.type == "submitAIArticle"
                                    ? "articleAI"
                                    : "articleAI",
                                articlesTotalAmount,
                                htmlContent,
                              },
                            });
                          }}
                        >
                          Back
                        </button>

                        <button
                          className="signUpBtn  me-3 mt-5"
                          type="button"
                          onClick={(e) => createNewArticle("0")}
                        >
                          Submit
                        </button>
                        <button
                          className="secondaryBtb_  me-3 mt-5"
                          type="button"
                          onClick={(e) => createNewArticle("1")}
                        >
                          Draft
                        </button>
                        <button
                          className="secondaryBtb_  me-3 mt-5"
                          type="button"
                          onClick={() => {
                            handleOpenModal("scheduleModal");
                          }}
                        >
                          Schedule
                        </button>
                      </>
                    )}
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              <div className="articleImage mt-4 text-center">
                {(selectedPublication?.bannerImgUpload?.length > 0 ||
                  selectedPublication?.photo?.length > 0) && (
                  <img
                    src={
                      selectedPublication?.bannerImgUpload ||
                      selectedPublication?.photo
                    }
                    className="img-fluid me-2 articleCover_Img"
                    alt="media_image"
                  />
                )}
              </div>

              {selectedPublication?.creditImgUpload && (
                <>
                  <p className="form_subheading mt-2 text-center">
                    {selectedPublication?.creditImgUpload}
                  </p>
                </>
              )}
              <div className="contentPreview_ mt-3">
                <div className="row">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: selectedPublication?.ckEditorContent,
                    }}
                  />
                </div>
                <div style={{ display: "none" }}>
                  <CKEditor
                    initData={""}
                    config={{
                      extraPlugins: "easyimage",
                      versionCheck: false,
                    }}
                  />
                </div>

                <div className="row articleSlider_Row">
                  <div className="slider-container mt-4 mb-4 articleMultiImg_Slider">
                    {selectedPublication?.multipleImages?.length > 1 ? (
                      <Slider {...settings}>
                        {selectedPublication?.multipleImages?.map(
                          (item, index) => (
                            <div className="p-2">
                              <a
                                key={index}
                                // href={logo?.link}
                                className="marquee_Links"
                                target="_blank"
                              >
                                <div className="client_Logoes">
                                  <img
                                    src={item}
                                    alt="slider_img"
                                    className="img-fluid w-100 h-100"
                                  />
                                </div>
                              </a>
                            </div>
                          )
                        )}
                      </Slider>
                    ) : (
                      <>
                        {selectedPublication?.multipleImages?.map((item) => (
                          <div className="col-md-4">
                            <div className="mt-3">
                              <img
                                src={item}
                                className="img-fluid articleCover_Img"
                                alt="media_image"
                              />
                            </div>
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                </div>

                {selectedPublication?.googleDriveUrl && (
                  <div className="articlePublication_ article_Publication_Resp mt-3">
                    <h5 className="heading_22">Google Drive Url :-</h5>
                    <div className="mediaInner_ d-flex align-items-center">
                      <a
                        className="form_subheading"
                        href={selectedPublication?.googleDriveUrl}
                        target="_blank"
                      >
                        {selectedPublication?.googleDriveUrl}
                      </a>
                    </div>
                  </div>
                )}

                {/* <h3 className=" articleeditor_ mt-3 position-relative justify-content-center articleUrl_show"> Article URL :- <span className="ps-2">{selectedPublication?.articleAddUrl}</span></h3> */}

                <div className="upload_Articles_Btn">
                  {location?.state?.type === "CreateArticle" ? (
                    <>
                      {/* <button
                        className="signUpBtn  mt-5 me-3"
                        type="button"
                        onClick={() => {
                          navigate("/article-details", {
                            state: {
                              ...location.state,
                              type: "submitArticle",
                            },
                          });
                        }}
                      >
                        Skip
                      </button> */}
                      <div className="d-flex align-items-center">
                        <button
                          className="signUpBtn  me-3 mt-3"
                          type="button"
                          onClick={() => {
                            navigate("/write-article", {
                              state: {
                                ...location?.state?.selectedPublicationDetails,
                                flag: "SelectedPublication",
                              },
                            });
                          }}
                        >
                          Back
                        </button>

                        <button
                          className="signUpBtn me-3 mt-3"
                          type="button"
                          onClick={() => {
                            navigate("/addOn-Article", {
                              state: {
                                ...location.state,
                                userInfo: userInfo,
                                selectedPublication: selectedPublication,
                                articlesTotalAmount: articlesTotalAmount,
                                type: "CreateArticle",
                                htmlContent,
                                // selectedOption:location?.state?.selectedOption
                              },
                            });
                          }}
                        >
                          Next
                        </button>
                      </div>

                      {/* <button
                        className="signUpBtn  mt-5 "
                        type="button"
                        onClick={() => {
                          navigate("/addOn-Article", {
                            state: {
                              ...location.state,
                              userInfo: userInfo,
                              selectedPublication: selectedPublication,
                              articlesTotalAmount: articlesTotalAmount,
                              type: "CreateArticle",
                              htmlContent
                            },
                          });
                        }}
                      >
                        Next
                      </button> */}
                    </>
                  ) : (
                    <>
                      <button
                        className="signUpBtn  me-3 mt-5"
                        type="button"
                        onClick={() => {
                          navigate("/addOn-Article", {
                            state: {
                              type: "CreateArticle",
                              userInfo,
                              selectedPublication,
                              articlesTotalAmount,
                              htmlContent,
                            },
                          });
                        }}
                      >
                        Back
                      </button>
                      <button
                        className="signUpBtn  me-3 mt-5"
                        type="button"
                        onClick={(e) => createNewArticle("0")}
                      >
                        Submit
                        {articleSelector?.loading && (
                          <span className="spinner-border spinner-border-sm ms-2"></span>
                        )}
                      </button>

                      <button
                        className="secondaryBtb_ mt-5 me-3 "
                        onClick={(e) => createNewArticle("1")}
                      >
                        Draft
                      </button>

                      <button
                        className="secondaryBtb_ mt-5  me-3 "
                        onClick={() => {
                          handleOpenModal("scheduleModal");
                        }}
                      >
                        Schedule
                      </button>
                    </>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </section>
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={true}
        mediumWidth={false}
        ids={
          modalDetail.flag === "scheduleModal"
            ? "ScheduleModal"
            : modalDetail.flag === "deleteModal"
            ? "deletCategoryModal"
            : modalDetail.flag === "underreviewModal"
            ? "underreviewModal"
            : modalDetail.flag === "paymentModal"
            ? "paymentModal"
            : modalDetail.flag === "paymentModal"
            ? "paymentModal"
            : modalDetail.flag === "cardPaymentModal"
            ? "cardPaymentModal"
            : ""
        }
        child={
          modalDetail.flag === "scheduleModal" ? (
            <ScheduleModal
              createNewArticle={(flag, time, date) =>
                createNewArticle(flag, time, date)
              }
            />
          ) : modalDetail.flag === "underreviewModal" ? (
            <UnderreviewModal close={() => handleOnCloseModal()} />
          ) : modalDetail.flag === "paymentModal" ? (
            <PaymentDetail
              close={() => handleOnCloseModal()}
              handleOpenModal={handleOpenModal}
              paymentToken={paymentToken}
              paymentFlag={"articlePayment"}
            />
          ) : modalDetail.flag === "cardPaymentModal" ? (
            <CardPaymentModal
              close={() => handleOnCloseModal()}
              paymentToken={paymentToken}
              paymentFlag={"articlePayment"}
              totalAmount={userInfo?.totalAmount || articlesTotalAmount}
              handleOpenModal={(data) => handleOpenModal(data)}
              articleLoader={articleLoader}
            />
          ) : (
            ""
          )
        }
        header={
          modalDetail.flag === "scheduleModal" ? (
            <>
              <h2 className="modal_Heading">Schedule For Later</h2>
              <p onClick={handleOnCloseModal} className="modal_cancel">
                <img src={images.modalCross} className="ModalCance" alt="" />
              </p>
            </>
          ) : modalDetail.flag === "paymentModal" ? (
            <>
              <h2 className="modal_Heading">Add Card</h2>
              <p onClick={handleOnCloseModal} className="modal_cancel">
                <img src={images.modalCross} className="ModalCance" alt="" />
              </p>
            </>
          ) : modalDetail.flag === "cardPaymentModal" ? (
            <>
              <h2 className="modal_Heading">Payment is for Publications</h2>
              <p onClick={handleOnCloseModal} className="modal_cancel">
                <img src={images.modalCross} className="ModalCance" alt="" />
              </p>
            </>
          ) : (
            ""
          )
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  );
};

export default ArticleDetails;
